import axios from "axios";
import { AUTH_USER_CG_TOKEN_KEY } from "./constant";
import store from "../redux/store";
// import { setIsTokenInvalid } from "../redux/slices/ComponentFunctionSlice";
import Cookies from "universal-cookie";
const cookies = new Cookies();

axios.interceptors.request.use(function (config) {
  if(!config.url.includes('/refresh')){
    const token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `${token}`;
    }
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const refreshToken = cookies.get(`${AUTH_USER_CG_TOKEN_KEY}_R`);
      console.log(refreshToken);
      // prevRequest.headers['Authorization'] = `Bearer ${refreshToken}`;
      const refreshTokenRes = await axios(
        {
          url: `${process.env.REACT_APP_BASEURL}/refresh`,
          method: "GET",
          headers: { Authorization: `${refreshToken}` },
          withCredentials: true,
          mode: "no-cors",
        }
      );
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, refreshTokenRes.data);
      prevRequest.headers["Authorization"] = `${refreshTokenRes.data}`;
      return httpCall(prevRequest);
    } else if (
      error?.response?.status === 403 &&
      error?.response?.data?.message === "Token Expired"
    ) {
      store.dispatch({ type: "USER_LOGGED_OUT" });
    }
    return Promise.reject(error);
  }
);
export const httpCall = async (config) => {
  let headerConfig;
  if (config?.headers) {
    headerConfig = {
      ...config,
      headers: {
        ...config.headers,
        "Cache-Control": "no-store,no-cache,",
        Pragma: "no-cache",
      },
    };
  } else {
    headerConfig = {
      ...config,
      headers: {
        "Cache-Control": "no-store,no-cache,",
        Pragma: "no-cache",
      },
    };
  }
  try {
    const response = await axios(headerConfig);
    return {
      success: true,
      data: response.data,
      ...response,
    };
  } catch (err) {
    return { success: false, err: err.response };
  }
};

export const GET_CUSTOMER_INFO_URL = "/v1/customers/getCustomerInfo";
export const REGISTER_PICKUP_LOCATION_URL = "/v1/address/registerPickupAdd";
export const UPDATE_CUSTOMER_ADDRESS_URL = "/v1/address/updatePickupAdd";
export const GET_ALL_PICKUP_URL = "/v1/address/getByCustomerId";
export const ADD_KYC_DETAILS_URL = "/v1/customers/kyc";
export const GET_KYC_DETAILS_URL = "/v1/customers/getCustomerDocs";
export const UPDATE_KYC_DETAILS_URL = "/v1/customers/updateDocuments";
export const GET_SUBSCRIBER_INFO_URL = "/v1/subscriber/getUserInfo";
export const SEND_OTP_URL = "/verify/sentotp";
export const RESEND_OTP_URL = "/verify/resendotp";
export const VERIFY_OTP_URL = "/verify/user";
export const UPDATE_PASSWORD_URL = "/verify/changePassword";
export const LOGIN_URL = "/auth";
export const DOWNLOAD_BOOKING_SAMPLEFILE_URL =
  "/v1/shipment/downloadBookingSampleFile";
export const UPLOAD_BOOKING_URL = "/v1/shipment/uplaodBookingFile";
// export const CANCEL_SHIPMENT_URL = "/v1/shipment/cancelBooking";
export const CANCEL_SHIPMENT_URL = "/v2/shipments/cancel";
export const UPLOAD_TRANSACTION_IMAGE_URL =
  "/v1/transaction/uploadTransactionImage";
export const CALCULATE_PRICE_URL = "/v1/serviceCharge/calculatePrice";
export const DELETE_SHIPMENT_URL = "/v1/shipment/deleteShipmentInfo";
// export const SINGLE_BOOKING_URL = "/v1/shipment/singleShipmentBooking";

export const SINGLE_BOOKING_URL = "/v2/shipments/manifest";
export const GET_ALL_SHIPMENT_URL = "/v1/shipment/allShipmentStatusByCustomer";
export const HANDLE_DELETE_IN_PICKUPTABLE_URL = "/v1/address/deletePickupAdd";
export const EDIT_PICKUP_FORMDATA_URL = "/v1/address/updatePickupAdd";
export const getUserData = "/v1/getPickupAddInfo/:id";
export const UPDATE_CUSTOMER_INFO_URL = "/v1/customers/updateCustomer";
////////////// payment Link
export const GET_PAYMENT_TOKEN_URL =
  "https://pay.easebuzz.in/payment/initiateLink";
export const GET_PAYMENT_ACCESS_KEY_URL = "/v1/payment/accessKey";
export const SAVE_TRANSACTION_URL =
  "/v1/transaction/registerTransactionDetails";
export const GET_ALL_IDENTITY_URL = "/v1/identityType/getAllIdentityType";
export const GET_AWB_STATUS_URL = "/v1/shipmentStatus/statusTrack";
export const GETALL_TRANSACTIONS_URL =
  "/v1/transaction/allTransactionStatusByCustomer";
export const GET_ALL_TRANSACTIONS =
  "/v1/transaction/getAllTransactionDetails/user";
export const GET_DASHBOARD_DATA_URL = "/v1/dashboard/customer";
export const UPDATE_BANKING_DETAILS_URL =
  "/v1/customerBankDetails/updateBankDetails";
export const GET_BANKING_DETAILS_URL = "/v1/customerBankDetails/getBankDetails";
export const GET_DISCREPANCY_DETAILS_URL = "/v1/discrepancy/shipments/user";
export const GET_ALL_REMITTANCE_URL = "/v1/pay/getAllTransaction";
export const SAVE_DEFAULT_ADDRESS_URL = "/v1/settings/address";
export const SAVE_DEFAULT_BANKACCOUNT_URL = "/v1/settings/default/bankAccount";
export const SAVE_AUTO_BOOKING_URL = "/v1/settings/autoBooking";
export const SAVE_COURIER_SETTINGS_URL = "/v1/settings/courier";
export const SAVE_PACKAGE_VOLUME_URL = "/v1/settings/package";
export const SAVE_MANIFEST_SETTING_URL = "/v1/settings/manifestSetting";
export const SAVE_FULFILMENT_SETTING_URL = "/v1/settings/channel";
export const GET_COURIER_SETTINGS_URL = "/v1/settings";
export const GET_PINCODE_DATA_URL = "/v1/pincodeCoverage/getPincodeInfo";
export const GET_EXCEL_DATA_URL = "/v1/download/files/user";
export const REQUEST_PICKUP_URL = "/v1/shipment/requestPickup/B2B";
export const GET_APP_CONFIG_B2C_URL = "/v1/config/setting/businessB2C";
export const GET_APP_CONFIG_B2B_URL = "/v1/config/setting/businessB2B";
