import React from "react";
import {
  HStack,
  Spinner,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Flex,
  Icon,
  Text,
  Menu,
  MenuItem,
  MenuDivider,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { GiWallet } from "react-icons/gi";
import { FaRupeeSign } from "react-icons/fa";
import { COLOR } from "../../utils/constant";
import {
  getCustomerInfo,
  getIsCustomerInfoLoading,
} from "../../redux/slices/CustomerDetailsSlice";
import RechargeModel from "../RechargeModal";
const WalletMenu = () => {
  const customerInfo = useSelector(getCustomerInfo);
  const isCustomerInfoLoading = useSelector(getIsCustomerInfoLoading);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleRecharge = () => {
    onOpen();
  };
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const amount = parseFloat(customerInfo?.walletAmount ?? "");
  return isCustomerInfoLoading ? (
    <Spinner />
  ) : (
    <>
      <Menu autoSelect>
        <MenuButton
          py={[1, 3]}
          transition="all 0.3s"
          _focus={{ boxShadow: "none" }}
        >
          <Flex gap={1} alignItems={["center", "flex-end"]}>
            <Icon as={GiWallet} w={[6, 7]} h={[6, 7]} color="white" />
            {isLessThan768 ? null : (
              <HStack gap={0} p={0} m={0}>
                <Icon as={FaRupeeSign} w={5} h={5} color="white" />
                <Text fontSize={["sm", "md"]} color="white">
                  {amount}
                </Text>
              </HStack>
            )}
          </Flex>
        </MenuButton>
        <MenuList bg={COLOR.blue} borderColor={COLOR.blue} zIndex={99}>
          <MenuItem
            w={"95%"}
            mx={"auto"}
            rounded={"md"}
            _hover={{ bg: "gray.500" }}
            bg="#274a69"
          >
            <Text color="white">
              Amount - <Icon as={FaRupeeSign} w={[1, 2, 3]} h={[1, 2, 3]} />{" "}
              {customerInfo?.walletAmount}
            </Text>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            w={"95%"}
            mx={"auto"}
            rounded={"md"}
            _hover={{ bg: "gray.500" }}
            bg="#274a69"
          >
            <Text color="white">
              Cashback - <Icon as={FaRupeeSign} w={[3]} h={[3]} />{" "}
              {customerInfo?.cashBack}
            </Text>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            w={"95%"}
            mx={"auto"}
            rounded={"md"}
            onClick={handleRecharge}
            _hover={{ bg: "gray.500" }}
            bg="#274a69"
          >
            <Text color="white">Recharge </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <RechargeModel isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default WalletMenu;
