import React from "react";
import {
  Heading,
  Stack,
  Text,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { CustomerSellPoint } from "../../../utils/utilityConstants";
const CustomerSellMedia = ({ onChange, value: defaultValue }) => {
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800">
        {" "}
        How do you sell your product ?
        <Text as="span" color="red">
          *
        </Text>{" "}
      </Heading>
      <Text fontSize={"lg"} letterSpacing={1.5} ml={[0, 0, 5]} mb={4}>
        (Select as many as applicable)
      </Text>

      <CheckboxGroup
        id="sellMedia"
        colorScheme="blue"
        defaultValue={defaultValue}
        onChange={(value) => onChange("sellMedia", value)}
      >
        <Stack spacing={4} direction={"column"}>
          <Checkbox value={CustomerSellPoint.MARKET_PLACE}>
            Online Marketplaces (like Amazon, Flipkart etc.)
            Online Marketplaces (like Amazon, Flipkart etc.)
          </Checkbox>
          <Checkbox value={CustomerSellPoint.WEBSITE}>Own Website</Checkbox>
          <Checkbox value={CustomerSellPoint.SOCIAL}>
            Social Media (like Facebook, Instagram, WhatsApp)
          </Checkbox>
          <Checkbox value={CustomerSellPoint.RETAILS}>
            Retail/Physical stores
          </Checkbox>
        </Stack>
      </CheckboxGroup>
    </>
  );
};

export default CustomerSellMedia;
