import { Flex, useMediaQuery, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import Calculatedrawer from "../Calculator/Calculaterdrawer";
import UserMenu from "./UserMenu";
import WalletMenu from "./WalletMenu";
import MobileMenu from "../../components/sidebar/MobileMenu";
import QuixgoText from "../../components/shared/QuixgoText";
import NavbarSearch from "./NavbarSearch";
import { BsWhatsapp } from "react-icons/bs";
export default function Navbar() {
  const loggedUser = useSelector(getLoggedUser);
  const [isLessThan540] = useMediaQuery("(max-width: 540px)");
  const [isLessThan768] = useMediaQuery("(max-width: 769px)");
  const [isLessThan1024] = useMediaQuery("(max-width: 1024px)");
  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
        px={[0, 5]}
      >
        <Flex alignItems={"center"}>
          {isLessThan768 && <MobileMenu />} <QuixgoText fontSize={["3xl"]} />
        </Flex>
        <HStack spacing={4}>
          {isLessThan1024 && (
            <Icon
              as={BsWhatsapp}
              boxSize="2rem"
              color="white"
              bg="#25d366"
              rounded="full"
              onClick={() =>
                window.open("https://wa.link/azk0d2", "_blank", "noopener")
              }
              cursor={"pointer"}
            />
          )}
          {!isLessThan540 && <NavbarSearch />}
          <Calculatedrawer />
          <WalletMenu />
          <UserMenu loggedUser={loggedUser} isSetting={true} />
        </HStack>
      </Flex>
      {isLessThan540 && <NavbarSearch w={"full"} roundedBottom={"none"} />}
    </>
  );
}
