import React from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
const useCustomAlertDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const openAlertDialog = () => {
    onOpen();
  };
  const closeAlertDialog = () => {
    onClose();
    setIsConfirmed(false);
  };
  const confirmAction = () => {
    setIsConfirmed(true);
    onClose();
  };
  const AlertDialogComponent = ({ title, body }) => (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              borderRadius={"sm"}
              ref={cancelRef}
              onClick={closeAlertDialog}
            >
              Cancel
            </Button>
            <Button
              borderRadius={"sm"}
              colorScheme="red"
              onClick={confirmAction}
              ml={3}
            >
              Save
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
  return {
    isConfirmed,
    openAlertDialog,
    AlertDialogComponent,
  };
};

export default useCustomAlertDialog;
