import { takeLatest, all, call, put, select } from "redux-saga/effects";
import {
  setTransactionDetailsLoading,
  setRemittancePaidList,
} from "../slices/TransactionDetailsSlice";
import { httpCall, GET_ALL_REMITTANCE_URL } from "../../utils/api";

import { toast } from "react-toastify";
import { getIsRouteB2bOrB2c } from "../slices/ComponentFunctionSlice";

export function* getRemittanceTransactions(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  
  let { postData } = params;
  const getAllRemittanceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_REMITTANCE_URL}/${pathName}`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const getAllRemittanceResponse = yield call(httpCall, {
      url: getAllRemittanceUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        
      },
      data: postData,
    });
    if (getAllRemittanceResponse?.success) {
      yield put(setRemittancePaidList(getAllRemittanceResponse?.data));
      yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchTransactionDetailsSaga() {
  yield takeLatest("GET_REMITTANCE_TRANSACTION", getRemittanceTransactions);
}

export default function* TransactionDetailsSaga() {
  yield all([watchTransactionDetailsSaga()]);
}
