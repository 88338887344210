import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Input,
  Select,
} from "@chakra-ui/react";
import { Controller, useWatch } from "react-hook-form";
export const B2bInsuranceType = ({ errors, control }) => {
  return (
    <FormControl isInvalid={errors.insuranceType}>
      <FormLabel htmlFor="insuranceType">
        Insurance Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name="insuranceType"
        control={control}
        rules={{ required: "This is required" }}
        render={({ field }) => (
      <RadioGroup {...field}
      >
        <Stack direction="row">
          <Radio value="carrier">Carrier Risk</Radio>
          <Radio value="owner">Owner Risk</Radio>
          {/* <Radio value="3">3rd Party Insurance</Radio> */}
        </Stack>
      </RadioGroup>
        )}
        />
      <FormErrorMessage>{errors?.insuranceType?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const B2bQuantityFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.quantity}>
      <FormLabel htmlFor="quantity">
        Quantity
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="quantity"
        type="number"
        placeholder="Enter Quantity "
        {...register("quantity", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const B2BServiceProviderFormControl = ({
  errors,
  setServiceProvider,
  serviceProvider,
  ServiceProviderList,
  register,
}) => {
  return (
    <FormControl isInvalid={errors.serviceProvider}>
      <FormLabel htmlFor="serviceProvider">
        Service Provider
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id="serviceProvider"
        placeholder="Select Service Provider"
        {...register("serviceProvider", {
          required: "This is required",
        })}
        onChange={(e) => setServiceProvider(e.target.value)}
        value={serviceProvider}
      >
        {ServiceProviderList.map((provider, i) => (
          <option key={provider.value} value={provider.value}>
            {provider.name}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors.serviceProvider && errors?.serviceProvider?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
