import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Controller} from "react-hook-form";

const PaymentModeFormControl = ({
  errors,
  control,
  isToPayAllowed=false,
  isPrepaidAllowed = true,
  isB2B = false,
}) => {
  return (
    <FormControl isInvalid={errors.paymentMode}>
      <FormLabel htmlFor="paymentMode">
        Order Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name="paymentMode"
        control={control}
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack spacing={5} direction="row">
              {isPrepaidAllowed && (
                <Radio colorScheme="blue" value="Prepaid">
                  Prepaid
                </Radio>
              )}
              <Radio colorScheme="blue" value="COD">
                COD
              </Radio>
              {isB2B &&isToPayAllowed&& (
                <Radio colorScheme="blue" value="TOPAY">
                  To-Pay
                </Radio>
              )}
            </Stack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{errors?.paymentMode?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default PaymentModeFormControl;