import React from "react";
import { useForm } from "react-hook-form";
import { Button, Box, Text, Grid, GridItem } from "@chakra-ui/react";
import { B2BSERVICEMODE } from "../../utils/utilityConstants";
import DimensionInput from "./DimensionInput";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomDeliveryPincodeFormControl,
  CustomPincodeFormControl,
  CalculatorWeightFormControl,
} from "./CalculatorField";

import PaymentModeFormControl from '../BookShipment/BookingFormField/PaymentModeControl';
import CodAmountFormControl from '../BookShipment/BookingFormField/CodAmountFormControl';
import InvoiceFormControl from '../BookShipment/BookingFormField/InvoiceFormControl';
import ServiceModeControl from '../BookShipment/BookingFormField/ServiceModeControl';
import { getCustomerInfo } from "../../redux/slices/CustomerDetailsSlice";
import { convertToCm } from "../../utils/utilsFunction";
import {
  B2bInsuranceType,
} from "../B2BContainer/B2BBookingFields";

const B2BcalculatorForm = ({ close, pathName }) => {
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerInfo);
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      volume: [{ count: "", height: "", length: "", width: "" }],
      paymentMode: "Prepaid",
      insuranceType: "carrier",
      serviceMode: "FORWARD",
      addressType: "Home",
    },
  });
  function onSubmit(values) {
    let errorOccurred = false;
    try {
      values?.volume.forEach((entry) => {
        entry.height = convertToCm(entry?.height, values?.unit);
        entry.length = convertToCm(entry?.length, values?.unit);
        entry.width = convertToCm(entry?.width, values?.unit);
      });
    } catch (error) {
      errorOccurred = true;
      console.error("Error during form submission:", error);
    } finally {
      if (errorOccurred) {
        console.log("Form submission failed");
      } else {
        const params = {
          ...values,
          codAmount:
            values && values?.paymentMode === "COD"
              ? Number(values?.codAmount)
              : undefined,
          customerId: loggedUser?.customerId,
        };
        dispatch({
          type: "CALCULATE_PRICE",
          payload: params,
          pathName: pathName,
        });
        navigate("/b2b/calculatePrice");
        close();
      }
    }
  }

  return (
    <Box color="blackAlpha.800">
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <PaymentModeFormControl
          errors={errors}
          control={control}
          isPrepaidAllowed={true}
          isToPayAllowed={true}
          isB2B={true}
        />
        <B2bInsuranceType errors={errors} control={control} />
        <ServiceModeControl
          controlId={"serviceMode"}
          label="Service Mode"
          errors={errors}
          control={control}
          options={B2BSERVICEMODE}
        />
        <CustomPincodeFormControl
          controlId="pickUpPincode"
          label="Pickup Pincode"
          placeholder="Pickup Pincode"
          register={register}
          errors={errors}
        />
        <CustomDeliveryPincodeFormControl
          controlId="deliveryPincode"
          label="Delivery Pincode"
          placeholder="Delivery Pincode"
          register={register}
          errors={errors}
        />
        {watch("paymentMode") === "COD" && (
          <CodAmountFormControl
            register={register}
            errors={errors}
            paymentMode={customerInfo?.paymentMode}
            isTopayCodAllowed={customerInfo?.isTopayCodAllowed}
            isB2B={true}
          />
        )}
        <InvoiceFormControl
          controlId="invoice"
          label="Invoice Value"
          register={register}
          errors={errors}
        />

        <CalculatorWeightFormControl
          controlId="weight"
          label="Total Weight (Kg)"
          register={register}
          errors={errors}
        />

        <GridItem colSpan={2}>
          <Text fontSize={"18px"} color={"blackAlpha.700"}>
            Dimensions{" "}
          </Text>

          <DimensionInput
            register={register}
            control={control}
            errors={errors}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              mt={4}
              colorScheme="blue"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </GridItem>
      </Grid>
    </Box>
  );
};
export default B2BcalculatorForm;
