import { httpCall, GET_DISCREPANCY_DETAILS_URL } from "../../utils/api";

import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  setDiscrepancy,
  setDiscrepancyLoading,
} from "../slices/DiscrepancyDetailsSlice";
import { getIsRouteB2bOrB2c } from "../slices/ComponentFunctionSlice";

export function* weightDiscrepancy(payload) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { postData } = payload;
  setDiscrepancyLoading(true);
  
  let weightUrl = `${process.env.REACT_APP_BASEURL}${GET_DISCREPANCY_DETAILS_URL}/${pathName}`;

  try {
    const discrepancyDetails = yield call(httpCall, {
      url: weightUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        
      },
      data: postData,
    });

    if (discrepancyDetails.data && discrepancyDetails.success) {
      yield put(setDiscrepancy(discrepancyDetails?.data));
      setDiscrepancyLoading(false);
    } else {
      toast.error("Something went wrong");
      setDiscrepancyLoading(false);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchDiscrepancy() {
  yield takeLatest("GET_ALL_DISCREPANCY_DETAILS", weightDiscrepancy);
}

export default function* DiscrepancyDetailsSaga() {
  yield all([watchDiscrepancy()]);
}
