import { combineReducers } from "redux";
import { AUTH_USER_CG_TOKEN_KEY,COURIER_SERVICE_LOGIN } from "../../utils/constant";
import Cookies from 'universal-cookie';
import UserDetailsSlice from "./UserDetailsSlice";
import CustomerDetailsSlice from "./CustomerDetailsSlice";
import BookingDetailsSlice from "./BookingDetailsSlice";
import PaymentDetailsSlice from "./PaymentDetailsSlice";
import DashboardDetailsSlice from "./DashboardDetailsSlice";
import DiscrepancyDetailsSlice from "./DiscrepancyDetailsSlice";
import TransactionDetailsSlice from "./TransactionDetailsSlice";
import CustomerSettingsSlice from "./CustomerSettingsSlice";
import ComponentFunctionSlice from "./ComponentFunctionSlice";
import ExcelDownloadSlice from "./ExcelDownloadSlice";
const cookies = new Cookies();
const appReducer = combineReducers({
  /* your app’s top-level reducers */
  UserDetailsSlice,
  CustomerDetailsSlice,
  BookingDetailsSlice,
  PaymentDetailsSlice,
  DashboardDetailsSlice,
  DiscrepancyDetailsSlice,
  TransactionDetailsSlice,
  CustomerSettingsSlice,
  ComponentFunctionSlice,
  ExcelDownloadSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    localStorage.removeItem(COURIER_SERVICE_LOGIN);
    localStorage.removeItem(AUTH_USER_CG_TOKEN_KEY);
    cookies.remove(`${AUTH_USER_CG_TOKEN_KEY}_R`)
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
