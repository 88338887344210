import React from "react";
import { Heading, VStack, RadioGroup, Radio } from "@chakra-ui/react";
const ShipmentCountForm = ({ onChange, value }) => {
  return (
    <>
      <Heading
        as="h2"
        textAlign={["start", "center"]}
        size={"lg"}
        color="blackAlpha.800"
        mb={4}
      >
        How many shipments do you ship in a month?
      </Heading>
      <RadioGroup
        onChange={(value) => onChange("avgShipment", value)}
        value={value}
      >
        <VStack spacing={4} align="stretch">
          <Radio value="10">0-10</Radio>
          <Radio value="50">10-50</Radio>
          <Radio value="100">50-100</Radio>
          <Radio value="500">100-500</Radio>
          <Radio value="1000">Above 500</Radio>
        </VStack>
      </RadioGroup>
    </>
  );
};

export default ShipmentCountForm;
