import { takeLatest, all, call, put, select } from "redux-saga/effects";
import {
  setCustomerInfo,
  setIdList,
  setIsCustomerInfoLoading,
  setIsBankDetailsLoading,
  setBankDetails,
  setKycDocs,
  setIsCustomerTypeLoading,
  setCustomerType,
  setIsKycDocsLoading,
} from "../slices/CustomerDetailsSlice";
import { getIsRouteB2bOrB2c } from "../slices/ComponentFunctionSlice";
import {
  httpCall,
  GET_CUSTOMER_INFO_URL,
  GET_ALL_IDENTITY_URL,
  UPDATE_CUSTOMER_INFO_URL,
  UPDATE_BANKING_DETAILS_URL,
  GET_BANKING_DETAILS_URL,
  ADD_KYC_DETAILS_URL,
  GET_KYC_DETAILS_URL,
  UPDATE_KYC_DETAILS_URL,
  UPDATE_CUSTOMER_ADDRESS_URL,
} from "../../utils/api";

import { toast } from "react-toastify";
export function* addKycInfo(params) {
  yield put(setIsCustomerInfoLoading(true));
  try {
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${ADD_KYC_DETAILS_URL}`;
    const { postData } = params;
    const { customerId } = postData;
    const customerUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (customerUpdateResponse?.success) {
      yield put(setIsCustomerInfoLoading(false));
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      localStorage.removeItem("customerFormData");
      yield getCustomerType({
        type: "GET_CUSTOMER_TYPE",
        payload: { id: customerId },
      });
    } else {
      toast.error(customerUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsCustomerInfoLoading(false));
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
  }
}
export function* getCustomerType(params) {
  yield put(setIsCustomerTypeLoading(true));

  const { payload } = params;
  const getCustomerUrl = `${process.env.REACT_APP_BASEURL}${GET_CUSTOMER_INFO_URL}/${payload.id}`;
  try {
    const customerType = yield call(httpCall, {
      url: getCustomerUrl,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (customerType?.success) {
      yield put(setCustomerType(customerType?.data));
      yield put(setIsCustomerTypeLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setIsCustomerTypeLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsCustomerTypeLoading(false));
  }
}
export function* getCustomerInfo(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  yield put(setIsCustomerInfoLoading(true));

  const { payload } = params;
  const getCustomerUrl = `${process.env.REACT_APP_BASEURL}${GET_CUSTOMER_INFO_URL}/${pathName}/${payload?.id}`;
  try {
    const customerDetails = yield call(httpCall, {
      url: getCustomerUrl,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      // data:data
    });
    if (customerDetails?.success) {
      yield put(setCustomerInfo(customerDetails.data));
      yield put(setIsCustomerInfoLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setIsCustomerInfoLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsCustomerInfoLoading(false));
  }
}

export function* getAllIdentityType(params) {
  try {
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_IDENTITY_URL}`;
    const idDetails = yield call(httpCall, {
      url: getAllIdUrl,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (idDetails?.success) {
      yield put(setIdList(idDetails.data));
    } else {
      toast.error("Id Type List fetch failed", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {}
}

export function* updateCustomerInfo(params) {
  yield put(setIsCustomerInfoLoading(true));
  try {
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_CUSTOMER_INFO_URL}`;
    const { postData } = params;
    const customerUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (customerUpdateResponse?.success) {
      console.log(customerUpdateResponse.data);
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      const params = {
        id: postData.customerInfo.customerId,
      };
      yield getCustomerInfo({
        type: "GET_CUSTOMER_INFO",
        payload: params,
      });
    } else {
      toast.error(customerUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsCustomerInfoLoading(false));
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
  }
}

export function* updateBankingDetails(params) {
  try {
    const { postData } = params;

    const updateBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_BANKING_DETAILS_URL}`;
    const formData = new FormData();
    formData.append("bankingInfo", JSON.stringify(postData.bankingInfo));

    yield put(setIsCustomerInfoLoading(true));
    if (Array.isArray(postData.cancelCheck)) {
      formData.append(
        "check",
        postData.cancelCheck[0],
        postData.cancelCheck[0].name
      );
    } else {
      formData.append("check", postData.cancelCheck);
    }
    const bankingDetailsResponse = yield call(httpCall, {
      url: updateBankingInfoUrl,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    });
    if (bankingDetailsResponse?.success) {
      const params = {
        customerId: postData.bankingInfo.customerId,
      };
      yield getBankingDetails({
        type: "GET_BANKING_DETAILS",
        postData: params,
      });
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsCustomerInfoLoading(false));
    } else {
      toast.error(bankingDetailsResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsCustomerInfoLoading(false));
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
  }
}

export function* getBankingDetails(params) {
  try {
    yield put(setIsBankDetailsLoading(true));
    const { postData } = params;

    const getBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${GET_BANKING_DETAILS_URL}`;
    const bankingDetailsResponse = yield call(httpCall, {
      url: getBankingInfoUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (bankingDetailsResponse?.success) {
      yield put(setBankDetails(bankingDetailsResponse.data));

      yield put(setIsBankDetailsLoading(false));
    } else {
      // toast.error('Something Went Wrong', {
      //     closeOnClick: true,
      //     draggable: true,

      // });
      yield put(setIsBankDetailsLoading(false));
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsBankDetailsLoading(false));
  }
}

export function* getKycDocs(params) {
  yield put(setIsKycDocsLoading(true));
  try {
    const { postData } = params;

    const getKycDocsUrl = `${process.env.REACT_APP_BASEURL}${GET_KYC_DETAILS_URL}`;
    const kycDocsResponse = yield call(httpCall, {
      url: getKycDocsUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (kycDocsResponse?.success) {
      const kycDocs = {};
      kycDocsResponse.data.forEach((docs) => (kycDocs[docs.docType] = docs));
      yield put(setKycDocs(kycDocs));
      yield put(setIsCustomerInfoLoading(false));
      yield put(setIsKycDocsLoading(false));
    } else {
      toast.error("Something Went Wrong", {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setKycDocs(setIsKycDocsLoading(false)));
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
    yield put(setIsKycDocsLoading(false));
  }
}

export function* putKycDocs(params) {
  try {
    const { postData } = params;
    const getKycDocsUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_KYC_DETAILS_URL}`;
    const formData = new FormData();
    formData.append("docData", JSON.stringify(postData.docData));
    formData.append("newFile", postData.file, postData?.file?.type);
    const kycDocsResponse = yield call(httpCall, {
      url: getKycDocsUrl,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    });
    if (kycDocsResponse?.success) {
      const params = {
        customerId: postData?.docData?.customerId,
      };
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getKycDocs({
        type: "GET_KYC_DOCS",
        postData: params,
      });
      yield getCustomerType({
        type: "GET_CUSTOMER_TYPE",
        payload: { id: postData?.docData?.customerId },
      });
      setIsCustomerInfoLoading(false);
    } else {
      toast.error("Something Went Wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
  }
}

export function* updateCustomerAddress(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  try {
    const { postData } = params;

    const updateAdressUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_CUSTOMER_ADDRESS_URL}/${pathName}`;
    const addressUpdateResponse = yield call(httpCall, {
      url: updateAdressUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (addressUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error("Something Went Wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
    yield put(setIsCustomerInfoLoading(false));
  }
}

export function* watchCustomerDetailsSaga() {
  yield takeLatest("GET_CUSTOMER_TYPE", getCustomerType);
  yield takeLatest("GET_CUSTOMER_INFO", getCustomerInfo);
  yield takeLatest("GET_ALL_IDENTITY_TYPE", getAllIdentityType);
  yield takeLatest("UPDATE_CUSTOMER_INFO", updateCustomerInfo);
  yield takeLatest("UPDATE_BANKING_DETAILS", updateBankingDetails);
  yield takeLatest("GET_BANKING_DETAILS", getBankingDetails);
  yield takeLatest("ADD_KYC_INFO", addKycInfo);
  yield takeLatest("GET_KYC_DOCS", getKycDocs);
  yield takeLatest("PUT_KYC_DOCS", putKycDocs);
  yield takeLatest("PUT_CUSTOMER_ADDRESS", updateCustomerAddress);
}

export default function* CustomerDetailsSaga() {
  yield all([watchCustomerDetailsSaga()]);
}
