import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Box,
} from "@chakra-ui/react";
import React from "react";

import { getUserEmailId } from "../../redux/slices/UserDetailsSlice";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const ResetPasswordForm = () => {
  const userEmailId = useSelector(getUserEmailId);
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const handlePasswordSubmit = () => {
    const values = getValues();
    const params = {
      password: values.password,
      email: userEmailId.email,
    };
    dispatch({ type: "UPDATE_PASSWORD", postData: params });
  };
  return (
    <>
      <form>
        <FormControl isInvalid={errors.password} w="450px">
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            type="password"
            // placeholder='abc@xyz.com'
            {...register("password", {
              required: true,
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.",
              },
            })}
          />
          <Box>
            <FormErrorMessage h="30px" w="400px" mb="2px">
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </Box>
        </FormControl>
        <FormControl isInvalid={errors.cpassword} mt="10px">
          <FormLabel htmlFor="cpassword">Confirm Password</FormLabel>
          <Input
            id="cpassword"
            type="password"
            // placeholder='abc@xyz.com'
            {...register("cpassword", {
              required: true,
              validate: (value) =>
                value === watch("password") || "Passwords must match.",
            })}
          />
          <FormErrorMessage h="25px" mt="-1">
            {errors.cpassword && errors.cpassword.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          borderRadius={"sm"}
          mt="5px"
          color={"blue.300"}
          // isLoading={isSubmitting}
          type="button"
          onClick={(e) => handlePasswordSubmit(e)}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default ResetPasswordForm;
