import { takeLatest, all, call, put, select } from "redux-saga/effects";
import {
  setPickupLocations,
  setCalculatedPrice,
  setShipmentList,
  setPickupDeleteRow,
  setIsPickupEdit,
  setShipmentStatus,
  setIsStatusLoading,
  setPickupLocationLoading,
  setShipmentListLoading,
  setIsPincodeDataLoading,
  setIsBooking,
  setIsBookingDone,
  setIsShipmentStatusSuccess,
  setIsRequestPickupLoading,
  setDeliveryPincodeData,
  setPickupPincodeData,
  setBookingCalculatedPrice,
  setSelectedShipmentInfo
} from "../slices/BookingDetailsSlice";
import {
  httpCall,
  REGISTER_PICKUP_LOCATION_URL,
  DOWNLOAD_BOOKING_SAMPLEFILE_URL,
  UPLOAD_BOOKING_URL,
  CALCULATE_PRICE_URL,
  SINGLE_BOOKING_URL,
  GET_ALL_SHIPMENT_URL,
  GET_ALL_PICKUP_URL,
  HANDLE_DELETE_IN_PICKUPTABLE_URL,
  EDIT_PICKUP_FORMDATA_URL,
  GET_AWB_STATUS_URL,
  CANCEL_SHIPMENT_URL,
  GET_PINCODE_DATA_URL,
  REQUEST_PICKUP_URL,
  DELETE_SHIPMENT_URL
} from "../../utils/api";

import { toast } from "react-toastify";
import download from "js-file-download";
import { getCustomerInfo } from "./CustomerDetailsSaga";
import {
  getFilterFormData,
  getIsRouteB2bOrB2c,
  getLimit,
  getPage,
} from "../slices/ComponentFunctionSlice";
import {getSuccessCount} from '../../utils/utilsFunction';
export function* fetchPickupLocation(payload) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { postData } = payload;

  const getAllPickupUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_PICKUP_URL}/${pathName}`;
  yield put(setPickupLocationLoading(true));
  try {
    const pickupLocationDetails = yield call(httpCall, {
      url: getAllPickupUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (pickupLocationDetails?.success) {
      yield put(setPickupLocations(pickupLocationDetails?.data));
      yield put(setPickupLocationLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setPickupLocationLoading(false));
  }
}

export function* calculatePrice(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  yield put(setCalculatedPrice(null));
  const { payload } = params;

  const getPriceUrl = `${process.env.REACT_APP_BASEURL}${CALCULATE_PRICE_URL}/${pathName}`;
  try {
    const priceDetails = yield call(httpCall, {
      url: getPriceUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
    if (priceDetails?.success) {
      if (
        priceDetails?.data?.priceList !== null &&
        priceDetails?.data?.priceList !== undefined
      ) {
        yield put(setCalculatedPrice(priceDetails.data));
      } else {
        toast.error(
          "Either pincode is not covered or something went wrong please contact to admin",
          {
            closeOnClick: true,
            draggable: true,
          }
        );
      }
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* BookingCalculatePrice(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  yield put(setBookingCalculatedPrice(null));
  const { payload } = params;

  const getPriceUrl = `${process.env.REACT_APP_BASEURL}${CALCULATE_PRICE_URL}/${pathName}`;
  try {
    const priceDetails = yield call(httpCall, {
      url: getPriceUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
    if (priceDetails?.success) {
      if (
        priceDetails?.data?.priceList !== null &&
        priceDetails?.data?.priceList !== undefined
      ) {
        yield put(setBookingCalculatedPrice(priceDetails.data));
      } else {
        toast.error(
          "Either pincode is not covered or something went wrong please contact to admin",
          {
            closeOnClick: true,
            draggable: true,
          }
        );
      }
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addPickupLocation(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { payload } = params;

  const addPickupLocationUrl = `${process.env.REACT_APP_BASEURL}${REGISTER_PICKUP_LOCATION_URL}/${pathName}`;
  yield put(setPickupLocationLoading(true));
  try {
    const customerDetails = yield call(httpCall, {
      url: addPickupLocationUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
    if (customerDetails?.success) {
      yield call(fetchPickupLocation, {
        customerId: customerDetails.data.customerId,
        pathName: pathName,
      });
      toast.success(" Pickup location genrated Successfuly", {
        closeOnClick: true,
        draggable: true,
      });
      yield fetchPickupLocation({
        type: "GET_PICKUP_LOCATION",
        postData: {
          limit: 10,
          page: 1,
          filter: {
            customerId: payload?.customerId,
            addressCategory: "pickup",
          },
        },
      });
    } else {
      toast.error("Duplicate Entry", {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setPickupLocationLoading(false));
    }
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong", {
      closeOnClick: true,
      draggable: true,
    });
  }
}

export function* downloadBookingSampleFile() {
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_BOOKING_SAMPLEFILE_URL}`;

  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "blob",
    });
    if (partnerDetails?.success) {
      const data = partnerDetails.data;
      download(data, "SampleBookingFile.xlsx");
      toast.success(" Sample File Downloaded", {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* uploadBulkBookingFile(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const { payload } = params;

  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("fileName", payload.file.name);
  formData.append("customerId", payload.customerId);
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_BOOKING_URL}/${pathName}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    });
    if (partnerDetails?.success) {
      yield fetchAllShipmentStatus({
        type: "GET_ALL_SHIPMENT_LIST",
        customerType: pathName,
        postData: {
          limit,
          page,
          filter: {
            customerId: payload?.customerId,
            isDeleted:"false",
            ...filterFormData,
          },
        },
      });
      yield getCustomerInfo({
        type: "GET_CUSTOMER_INFO",
        payload: { id: payload?.customerId },
      });
      toast.success("File Uploaded Successfully. It is processing", {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* singleShipmentBooking(params) {
  yield put(setIsBookingDone(true));
  let pathName = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const { postData,listType } = params;

  const getBookingUrl = `${process.env.REACT_APP_BASEURL}${SINGLE_BOOKING_URL}/${pathName}`;
  try {
    const bookingDetails = yield call(httpCall, {
      url: getBookingUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (bookingDetails?.success) {
      yield put(setSelectedShipmentInfo([]))
      const count=getSuccessCount(bookingDetails.data)
      toast.success(
        `${count} out of ${bookingDetails.data.length} Shipment Booked Successfully`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBooking(true));
      yield put(setIsBookingDone(false));
      yield fetchAllShipmentStatus({
        type: "GET_ALL_SHIPMENT_LIST",
        postData: {
          limit,
          page,
          filter: {
            customerId: Array.isArray(postData)?postData[0]?.customerId: postData?.customerId,
            currentStatus:listType,
            isDeleted:"false",
            ...filterFormData,
          },
        },
      });
      yield getCustomerInfo({
        type: "GET_CUSTOMER_INFO",
        payload: { id: Array.isArray(postData)?postData[0]?.customerId: postData?.customerId },
      });
    } else {
      toast.error(
        bookingDetails?.err?.data
          ? bookingDetails.err.data
          : "Something Went wrong",
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBooking(false));
      yield put(setIsBookingDone(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* fetchAllShipmentStatus(payload) {
  yield put(setShipmentListLoading(true));
  let pathName = yield select(getIsRouteB2bOrB2c);

  const { postData } = payload;
  const getShipmentUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_SHIPMENT_URL}/${pathName}`;
  try {
    const shipmentDetails = yield call(httpCall, {
      url: getShipmentUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (shipmentDetails?.success) {
      yield put(setShipmentList(shipmentDetails.data));
      yield put(setShipmentListLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setShipmentListLoading(false));
  }
}

export function* DeletePickupDataFromTable(payload) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { postData, allFetchLocationParam } = payload;

  const DeletePickupDataURL = `${process.env.REACT_APP_BASEURL}${HANDLE_DELETE_IN_PICKUPTABLE_URL}/${pathName}`;

  try {
    const Getdelete = yield call(httpCall, {
      url: DeletePickupDataURL,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (Getdelete?.success) {
      toast.success(`Delete Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      yield put(setPickupDeleteRow(Getdelete.data));
      yield fetchPickupLocation({
        type: "GET_PICKUP_LOCATION",
        postData: {
          limit: 10,
          page: 1,
          filter: {
            customerId: allFetchLocationParam?.customerId,
            addressCategory: "pickup",
          },
        },
      });
    }
  } catch (err) {
    toast.error(`Something Went Wrong`, {
      closeOnClick: true,
      draggable: true,
      theme: "colored",
    });
  }
}

export function* editPickupFormdata(payload) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { postData, allFetchLocationParam } = payload;

  const EditPickupDataURL = `${process.env.REACT_APP_BASEURL}${EDIT_PICKUP_FORMDATA_URL}/${pathName}`;
  try {
    const GetEdit = yield call(httpCall, {
      url: EditPickupDataURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (GetEdit?.success) {
      yield put(setIsPickupEdit(GetEdit.data)); //function call //yield call(function name,parameter)
      toast.success(`Update Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      yield fetchPickupLocation({
        type: "GET_PICKUP_LOCATION",
        postData: {
          limit: 10,
          page: 1,
          filter: {
            customerId: postData?.customerId,
            addressCategory: "pickup",
          },
        },
      });
    }
  } catch (err) {
    toast.error("Something Went Wrong", {
      closeOnClick: true,
      draggable: true,
      theme: "colored",
    });
  }
}

export function* getAwbStatus(payload) {
  yield put(setIsStatusLoading(true));
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  yield put(setShipmentStatus({}));
  const getAwbStatusURL = `${process.env.REACT_APP_BASEURL}${GET_AWB_STATUS_URL}/${isB2bOrB2c}`;
  const { postData } = payload;
  try {
    const getStatus = yield call(httpCall, {
      url: getAwbStatusURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json", //json
      },
      data: postData,
    });
    if (getStatus?.success) {
      yield put(setShipmentStatus(getStatus?.data));
      yield put(setIsStatusLoading(false));
      yield put(setIsShipmentStatusSuccess(true));
    } else {
      yield put(setShipmentStatus(getStatus?.err?.data));
      yield put(setIsShipmentStatusSuccess(false));
      yield put(setIsStatusLoading(false));
      toast.error(getStatus?.err?.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    yield put(setIsStatusLoading(false));
    console.log(err);
  }
}

export function* cancelShipment(payload) {
  yield put(setIsBookingDone(true));
  const isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);

  const cancelShipmentURL = `${process.env.REACT_APP_BASEURL}${CANCEL_SHIPMENT_URL}/${isB2bOrB2c}`;
  const { postData } = payload;
  try {
    const cancelResponse = yield call(httpCall, {
      url: cancelShipmentURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json", //json
      },
      data: postData,
    });
    if (cancelResponse?.success) {
      yield put(setSelectedShipmentInfo([]))
      yield fetchAllShipmentStatus({
        type: "ALL_SHIPMENT_STATUS",
        postData: {
          limit,
          page,
          filter: {
            customerId: Array.isArray(postData)?postData[0]?.customerId: postData?.customerId,
            isDeleted:"false",
            ...filterFormData,
          },
        },
      });
      yield getCustomerInfo({
        type: "GET_CUSTOMER_INFO",
        payload: { id: Array.isArray(postData)?postData[0]?.customerId: postData.customerId},
      });

    
      const count=getSuccessCount(cancelResponse.data)
      toast.success(
        `${count} out of ${cancelResponse.data.length} Shipment Marked As Canceled Successfully`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBookingDone(false));
    } else {
      toast.error(
        `Unable to process your request. please try after some time or contact admin`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
    }
  } catch (err) {
    yield put(setIsStatusLoading(false));
    console.log(err);
  }
}
export function* getDeliveryPincodeData(params) {
  yield put(setIsPincodeDataLoading(true));
  const { postData } = params;

  const getPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const pincodeDetails = yield call(httpCall, {
      url: getPincodeDataUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (pincodeDetails?.success) {
      yield put(setDeliveryPincodeData(pincodeDetails?.data));
      yield put(setIsPincodeDataLoading(false));
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsPincodeDataLoading(false));
    }
  } catch (err) {
    yield put(setIsPincodeDataLoading(true));
    console.log(err);
  }
}
export function* getPickupPincodeData(params) {
  const { postData } = params;

  const getPickupPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const PickupPincodeDetails = yield call(httpCall, {
      url: getPickupPincodeDataUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (PickupPincodeDetails?.success) {
      yield put(setPickupPincodeData(PickupPincodeDetails?.data));
    } else {
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* Request_Pickup(params) {
  yield put(setIsRequestPickupLoading(true));
  const { postData } = params;

  const RequestPickupUrl = `${process.env.REACT_APP_BASEURL}${REQUEST_PICKUP_URL}`;
  try {
    const RequestPickupDetails = yield call(httpCall, {
      url: RequestPickupUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (RequestPickupDetails?.success) {
      yield put(setIsRequestPickupLoading(false));
      toast.success(`${RequestPickupDetails?.data}`, {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      yield put(setIsRequestPickupLoading(false));
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* Delete_Shipment(params) {
  yield put(setIsBookingDone(true));
  const { postData,listType } = params; 
  let pathName = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  // const filterFormData = yield select(getFilterFormData);
  const deleteShipmentUrl = `${process.env.REACT_APP_BASEURL}${DELETE_SHIPMENT_URL}/${pathName}`
  try {
    const deleteResponse = yield call(httpCall, {
      url: deleteShipmentUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (deleteResponse?.success) {
      yield put(setSelectedShipmentInfo([]))
      yield put(setIsRequestPickupLoading(false));
      yield fetchAllShipmentStatus({
        type: "GET_ALL_SHIPMENT_LIST",
        postData: {
          limit,
          page,
          filter: {
            customerId: Array.isArray(postData)?postData[0]?.customerId: postData?.customerId,
            currentStatus:listType,
            isDeleted:"false",
          },
        },
      });
      const count=getSuccessCount(deleteResponse.data)
      toast.success(
        `${count} out of ${deleteResponse.data.length} Shipment Deleted Successfully`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBookingDone(false));
    } else {
      yield put(setIsRequestPickupLoading(false));
      toast.error("Something went wrong", {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchBookingDetialsSaga() {
  yield takeLatest("GET_PICKUP_LOCATION", fetchPickupLocation);
  yield takeLatest("ADD_PICKUP_LOCATION", addPickupLocation);
  yield takeLatest("DOWNLOAD_BOOKING_SAMPLE", downloadBookingSampleFile);
  yield takeLatest("UPLOAD_BULK_BOOKING_FILE", uploadBulkBookingFile);
  yield takeLatest("CALCULATE_PRICE", calculatePrice);
  yield takeLatest("BOOKING_CALCULATE_PRICE", BookingCalculatePrice);
  yield takeLatest("SINGLE_SHIPMENT_BOOKING", singleShipmentBooking);
  yield takeLatest("GET_ALL_SHIPMENT_LIST", fetchAllShipmentStatus);
  yield takeLatest("HANDLE_DELETE", DeletePickupDataFromTable);
  yield takeLatest("EDIT_PICKUP_FORMDATA", editPickupFormdata);
  yield takeLatest("GET_AWB_STATUS", getAwbStatus);
  yield takeLatest("CANCEL_SHIPMENT", cancelShipment);
  yield takeLatest("GET_DEL_PINCODE_DATA", getDeliveryPincodeData);
  yield takeLatest("GET_PIC_PINCODE_DATA", getPickupPincodeData);
  yield takeLatest("REQUEST_PICKUP", Request_Pickup);
  yield takeLatest("DELETE_SHIPMENT",Delete_Shipment)
  // yield takeLatest('MODEL_CUSTOMER_INFO_FORM', fetchAllCustomerinfo);
}

export default function* BookingDetailsSaga() {
  yield all([watchBookingDetialsSaga()]);
}
