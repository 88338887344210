import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordPage from "../pages/ResetPassword.page";
import PageNotFound from "./PageNotFound.routes";
import RoutesConfig from "./config.routes";
import PrivateLayout from "../layouts/private.layouts";
import Layout from "../layouts/Layout";
import {
  setLoggedUserDetails,
  getLoggedUser,
  getUserLoader,
} from "../redux/slices/UserDetailsSlice";
import {
  COURIER_SERVICE_LOGIN,
  AUTH_USER_CG_TOKEN_KEY,
} from "../utils/constant";
import LoginSplitScreen from "../pages/Loginside.page";
import {
  PrivateRoutes,
  IsKycRoutes,
  IsB2bRoutes,
  IsB2cRoutes,
} from "../components/middleware/PrivateRoutes";
import B2bRoutesConfig from "./b2b.routes";
import MainNavbar from "../components/shared/MainNavbar";
import HomeRoutesConfig from "./home.routes";
import CustomerInfoModel from "../containers/CustomerInfoModal";
import PublicLayout from "../layouts/public.layouts";
const QuixRoutes = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const isDetailsFetching = useSelector(getUserLoader);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(isDetailsFetching);
  }, [isDetailsFetching]);
  useEffect(() => {
    const isRememberTrue = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const isLoggedIn = JSON.parse(localStorage.getItem(COURIER_SERVICE_LOGIN));
    if (isRememberTrue && !isLoggedIn && !isLoading) {
      setIsLoading(true);
      dispatch({ type: "User_INFO", postData: isRememberTrue });
    } else if (!loggedUser && isLoggedIn?.token && !isLoading) {
      const userDetails = JSON.parse(
        localStorage.getItem(COURIER_SERVICE_LOGIN)
      );
      dispatch(setLoggedUserDetails(userDetails));
    }
  }, [dispatch, isLoading, loggedUser]);
  const addPathPrefix = (routes, prefix) =>
    routes.map((route) => ({ ...route, path: `${prefix}${route.path}` }));

  const prefixedB2bPrivateRoute = addPathPrefix(
    B2bRoutesConfig.privateRoute,
    "/b2b"
  );
  const prefixedB2cPrivateRoute = addPathPrefix(
    RoutesConfig.privateRoute,
    "/b2c"
  );
  return (
    <Routes>
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/" element={<MainNavbar />}>
          <Route path="/" element={<IsKycRoutes />}>
            {HomeRoutesConfig?.homeRoutes.map((homeRoute) => (
              <Route key={homeRoute?.path} {...homeRoute} />
            ))}
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="kyc" element={<CustomerInfoModel />} />
          </Route>
        </Route>
        <Route path="/" element={<IsB2bRoutes />}>
          <Route path="b2b" element={<PrivateLayout />}>
            {prefixedB2bPrivateRoute.map((publicRoute) => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
        </Route>
        <Route path="/" element={<IsB2cRoutes />}>
          <Route path="b2c" element={<PrivateLayout />}>
            {prefixedB2cPrivateRoute.map((publicRoute) => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="/" element={<PublicLayout />}>
        <Route path="login" element={<LoginSplitScreen />} />
        <Route path="resetPassword" element={<ResetPasswordPage />} />
      </Route>
    </Routes>
  );
};

export default QuixRoutes;
