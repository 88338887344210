import { createSlice } from "@reduxjs/toolkit";
const set=new Set()
export const BookingDetailsSlice = createSlice({
  name: "BookingDetailsSlice",
  initialState: {
    PickupLocations: [],
    calCulatedPrice: null,
    BookingCalculatedPrice: null,
    shipmentList: [],
    pickupDeleteRow: [],
    selectedPickupAddress: null,
    selectedShipmentInfo:[] ,
    isPickupEdit: false,
    shipmentStatus: null,
    isStatusLoading: true,
    isPickupLocationLoading: false,
    isShipmentListLoading: false,
    DeliveryPincodeData: null,
    PickupPincodeData: null,
    isPincodeDataLoading: false,
    isBooking: false,
    isBookingDone: false,
    isShipmentStatusSuccess: false,
    isRequestPickupLoading: false,
  },

  reducers: {
    setPickupLocations: (state, action) => {
      state.PickupLocations = action.payload;
    },
    setIsRequestPickupLoading: (state, action) => {
      state.isRequestPickupLoading = action.payload;
    },
    setCalculatedPrice: (state, action) => {
      state.calCulatedPrice = action.payload;
    },
    setBookingCalculatedPrice: (state, action) => {
      state.BookingCalculatedPrice = action.payload;
    },
    setShipmentList: (state, action) => {
      state.shipmentList = action.payload;
    },
    setPickupDeleteRow: (state, action) => {
      state.pickupDeleteRow = action.payload;
    },
    setSelectedPickupAddress: (state, action) => {
      state.selectedPickupAddress = action.payload;
    },
    setIsPickupEdit: (state, action) => {
      state.selectedIsPickupEdit = action.payload;
    },
    setSelectedShipmentInfo: (state, action) => {
      state.selectedShipmentInfo = action.payload;
    },
    setShipmentStatus: (state, action) => {
      state.shipmentStatus = action.payload;
    },
    setIsShipmentStatusSuccess: (state, action) => {
      state.isShipmentStatusSuccess = action.payload;
    },
    setIsStatusLoading: (state, action) => {
      state.isStatusLoading = action.payload;
    },
    setPickupLocationLoading: (state, action) => {
      state.isPickupLocationLoading = action.payload;
    },
    setShipmentListLoading: (state, action) => {
      state.isShipmentListLoading = action.payload;
    },
    setDeliveryPincodeData: (state, action) => {
      state.DeliveryPincodeData = action.payload;
    },
    setPickupPincodeData: (state, action) => {
      state.PickupPincodeData = action.payload;
    },
    setIsPincodeDataLoading: (state, action) => {
      state.isPincodeDataLoading = action.payload;
    },
    setIsBooking: (state, action) => {
      state.isBooking = action.payload;
    },
    setIsBookingDone: (state, action) => {
      state.isBookingDone = action.payload;
    },
  },
});

export const {
  setPickupLocations,
  setCalculatedPrice,
  setShipmentList,
  setPickupDeleteRow,
  setSelectedPickupAddress,
  setIsPickupEdit,
  setSelectedShipmentInfo,
  setIsShipmentStatusSuccess,
  setShipmentStatus,
  setIsStatusLoading,
  setPickupLocationLoading,
  setShipmentListLoading,
  setDeliveryPincodeData,
  setPickupPincodeData,
  setIsPincodeDataLoading,
  setIsBooking,
  setIsBookingDone,
  setIsRequestPickupLoading,
  setBookingCalculatedPrice,
} = BookingDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getPickupLocations = (state) =>
  state.BookingDetailsSlice.PickupLocations;
export const getBookingStatus = (state) => state.BookingDetailsSlice.isBooking;
export const getIsRequestPickupLoading = (state) =>
  state.BookingDetailsSlice.isRequestPickupLoading;
export const getIsBookingDone = (state) =>
  state.BookingDetailsSlice.isBookingDone;
export const getCalculatedPrice = (state) =>
  state.BookingDetailsSlice.calCulatedPrice;
export const getAllShipmentList = (state) =>
  state.BookingDetailsSlice.shipmentList;
export const getAllpickupDeleteRow = (state) =>
  state.BookingDetailsSlice.pickupDeleteRow;
export const getSelectedPickupAddress = (state) =>
  state.BookingDetailsSlice.selectedPickupAddress;
export const getIspickupEdit = (state) =>
  state.BookingDetailsSlice.isPickupEdit;
export const getSelectedShipmentInfo = (state) =>
  state.BookingDetailsSlice.selectedShipmentInfo;
export const getShipmentStatus = (state) =>
  state.BookingDetailsSlice.shipmentStatus;
export const getBookingCalculatedPrice = (state) =>
  state.BookingDetailsSlice.BookingCalculatedPrice;
export const getIsShipmentStatusSuccess = (state) =>
  state.BookingDetailsSlice.isShipmentStatusSuccess;
export const getIsStatusLoading = (state) =>
  state.BookingDetailsSlice.isStatusLoading;
export const getIsPickupLocationLoading = (state) =>
  state.BookingDetailsSlice.isPickupLocationLoading;
export const getShipmentListLoading = (state) =>
  state.BookingDetailsSlice.isShipmentListLoading;
export const getDeliveryPincodeData = (state) =>
  state.BookingDetailsSlice.DeliveryPincodeData;
export const getPickupPincodeData = (state) =>
  state.BookingDetailsSlice.PickupPincodeData;
export const getPincodeDataLoading = (state) =>
  state.BookingDetailsSlice.isPincodeDataLoading;
///we can also write thunks by hand , which may contain both sync and async logic

export default BookingDetailsSlice.reducer;
