import { FiBarChart2 } from "react-icons/fi";
import { MdTrackChanges, MdOutlineHomeWork } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { CgChanel } from "react-icons/cg";
import { HiTruck, HiCurrencyRupee, HiOutlineScale } from "react-icons/hi";
export const B2bSidebarOptions = [
  {
    name: "Dashboard",
    icon: FiBarChart2,
    pathname: "/b2b/dashboard",
  },

  {
    name: "Shipment",
    icon: MdTrackChanges,
    pathname: "/b2b/shipmentstatus",
  },

  {
    name: "Shipping partners",
    icon: HiTruck,
    pathname: "/b2b/shippingpartners",
  },
  {
    name: "Pickup points",
    icon: MdOutlineHomeWork,
    pathname: "/b2b/pickuppoints",
  },
  {
    name: "Billing",
    icon: FaMoneyBillAlt,
    pathname: "/b2b/billing",
  },
  {
    name: "Weight Discrepancy",
    icon: HiOutlineScale,
    pathname: "/b2b/weightmanagement",
  },
  {
    name: "Remittance",
    icon: HiCurrencyRupee,
    pathname: "/b2b/remittance",
  },
];
export const B2cSidebarOptions = [
  {
    name: "Dashboard",
    icon: FiBarChart2,
    pathname: "/b2c/dashboard",
  },

  {
    name: "Shipment",
    icon: MdTrackChanges,
    pathname: "/b2c/shipmentstatus",
  },

  {
    name: "Shipping partners",
    icon: HiTruck,
    pathname: "/b2c/shippingpartners",
  },
  {
    name: "Pickup points",
    icon: MdOutlineHomeWork,
    pathname: "/b2c/pickuppoints",
  },
  {
    name: "Billing",
    icon: FaMoneyBillAlt,
    pathname: "/b2c/billing",
  },
  {
    name: "Weight Discrepancy",
    icon: HiOutlineScale,
    pathname: "/b2c/weightmanagement",
  },
  {
    name: "Remittance",
    icon: HiCurrencyRupee,
    pathname: "/b2c/remittance",
  },
  {
    name: "Channel",
    icon: CgChanel,
    pathname: "/b2c/channel",
  },
];
