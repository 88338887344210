import { Flex, Icon, Link, Tooltip } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { B2bSidebarOptions, B2cSidebarOptions } from "./sidebar.options";
import { COLOR } from "../../utils/constant";
import React from "react";
import "./sidebar.scss";
import { useSelector } from "react-redux";
import { getIsRouteB2bOrB2c } from "../../redux/slices/ComponentFunctionSlice";
export default function Sidebar() {
  const IsRouteB2bOrB2c = useSelector(getIsRouteB2bOrB2c);
  const SidebarOptions =
    IsRouteB2bOrB2c === "B2B" ? B2bSidebarOptions : B2cSidebarOptions;
  return (
    <Flex
      className="sidebar-menu-container"
      bg={COLOR.blue}
      alignItems="center"
      flexDir={"column"}
    >
      {SidebarOptions.map((menuItem, index) => {
        return (
          <Tooltip
            key={index}
            label={menuItem.name}
            placement="right"
            bg="#274A69"
            hasArrow
            p={2}
            rounded={"md"}
            ml={"-1"}
          >
            <Link
              key={index}
              as={NavLink}
              to={menuItem}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              p={3}
              ml={4}
              pl={5}
              roundedLeft={"1rem"}
              overflow={"hidden"}
            >
              <Icon
                as={menuItem.icon}
                fontSize="xl"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              />
            </Link>
          </Tooltip>
        );
      })}
    </Flex>
  );
}
