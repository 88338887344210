/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { getCustomerType } from "../redux/slices/CustomerDetailsSlice";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const customer = useSelector(getCustomerType);
  useEffect(() => {
    const isKyc = customer?.isDocumentUploaded;
    if (isKyc) {
      navigate(from, { replace: true });
    }
  }, []);
  return (
    <main className="App">
      <Outlet />
    </main>
  );
};

export default Layout;
