import React from "react";
import { Flex, Grid, GridItem, Icon, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import QuixgoText from "./QuixgoText";
import { COLOR } from "../../utils/constant";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import UserMenu from "../../containers/navbar/UserMenu";
import Whatsapp from "../../pages/Whatsapp";
import { getIsCustomerTypeLoading } from "../../redux/slices/CustomerDetailsSlice";
import ContentLoader from "../Loader/ContentLoader";
import { Bounce, toast } from "react-toastify";
import { BsWhatsapp } from "react-icons/bs";
const MainNavbar = ({ ...props }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const isCustomerTypeLoading = useSelector(getIsCustomerTypeLoading);
  const [isLessThan1024] = useMediaQuery("(max-width: 1024px)");
  React.useLayoutEffect(() => {
    if (loggedUser?.customerId) {
      dispatch({
        type: "GET_CUSTOMER_TYPE",
        payload: { id: loggedUser?.customerId },
      });
    }
  }, [loggedUser]);

  // React.useEffect(() => {
  //   toast.info(
  //     "Dear User, New Quixgo Ui has been updated, if you find any unpleasure output kindly take a screenshot and share with us on Whatsapp or on Email.  ",
  //     {
  //       position: "top-left",
  //       autoClose: 30000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //       transition: Bounce,
  //       style: {
  //         width: "90vw",
  //         margin: "auto",
  //         borderRadius: 0,
  //         fontWeight: "bold",
  //         fontSize: "1rem",
  //       },
  //     }
  //   );
  // }, []);
  return (
    <Grid templateRows="repeat(12, 1fr)" h={"100vh"}>
      <GridItem
        display={"flex"}
        rowSpan={1}
        alignItems="center"
        justifyContent={"space-between"}
        bg={COLOR.blue}
        px={8}
        {...props}
      >
        <QuixgoText fontSize={["lg", "3xl"]} />
        <Flex gap={4} alignItems={"center"}>
          {isLessThan1024 && (
            <Icon
              as={BsWhatsapp}
              boxSize="2rem"
              color="white"
              bg="#25d366"
              rounded="full"
              onClick={() =>
                window.open("https://wa.link/azk0d2", "_blank", "noopener")
              }
              cursor={"pointer"}
            />
          )}
          <UserMenu loggedUser={loggedUser} />
        </Flex>
      </GridItem>
      <GridItem rowSpan={11}>
        {isCustomerTypeLoading ? <ContentLoader /> : <Outlet />}
        {!isLessThan1024 && <Whatsapp />}
      </GridItem>
    </Grid>
  );
};

export default MainNavbar;
