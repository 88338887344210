import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { AUTH_USER_CG_TOKEN_KEY } from "../../utils/constant";
import { getCustomerType } from "../../redux/slices/CustomerDetailsSlice";
import { useSelector } from "react-redux";
export const PrivateRoutes = () => {
  const isLoggedIn = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export const IsKycRoutes = () => {
  const customer = useSelector(getCustomerType);
  const isKyc = customer?.isDocumentUploaded;
  return isKyc ? <Outlet /> : <Navigate to="/kyc" replace />;
};

export const IsB2bRoutes = () => {
  const { isB2bAccount, isB2bActive, isActive, isDeleted } =
    useSelector(getCustomerType) || {};
  const isB2bAllowed = (isB2bAccount || isB2bActive) && isActive && !isDeleted;
  return isB2bAllowed ? <Outlet /> : <Navigate to="/" replace />;
};
export const IsB2cRoutes = () => {
  const { isB2cAccount, isB2cActive, isActive, isDeleted } =
    useSelector(getCustomerType) || {};
  const isB2cAllowed = (isB2cAccount || isB2cActive) && isActive && !isDeleted;
  return isB2cAllowed ? <Outlet /> : <Navigate to="/" replace />;
};
