import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  setLoggedUserDetails,
  setUserInfoLoader,
  setUserEmailId,
  setUserVerifyLoader,
  setUserVerificationError,
  setUserVerifyStatus,
  setPasswordChanges,
  setEmailLoader,
} from "../slices/UserDetailsSlice";
import {
  httpCall,
  LOGIN_URL,
  GET_SUBSCRIBER_INFO_URL,
  SEND_OTP_URL,
  RESEND_OTP_URL,
  VERIFY_OTP_URL,
  UPDATE_PASSWORD_URL,
} from "../../utils/api";
import {
  AUTH_USER_CG_TOKEN_KEY,
  COURIER_SERVICE_LOGIN,
} from "../../utils/constant";
import qs from "qs";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export function* LoginUser(params) {
  try {
    const { postData } = params;
    yield put(setUserInfoLoader(true));
    let data = qs.stringify(postData);
    const loginUrl = `${process.env.REACT_APP_BASEURL}${LOGIN_URL}`;
    const loggedUserValue = yield call(httpCall, {
      url: loginUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
        credentials: 'include'
      },
      maxBodyLength: Infinity,
      data: postData//data,
    });
    if (loggedUserValue?.success) {
      const { token } = loggedUserValue.data;
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      // if (postData.remember) {
      //   localStorage.setItem(
      //     AUTH_USER_CG_TOKEN_KEY,
      //     `${token}_=${logedUserValue.data.annotation_id}`
      //   );
      // }
      yield put(setUserInfoLoader(false));
      cookies.set(`${AUTH_USER_CG_TOKEN_KEY}_R`, loggedUserValue.data.refreshToken, { path: '/' });
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      delete loggedUserValue.data.refreshToken;
      localStorage.setItem(
        COURIER_SERVICE_LOGIN,
        JSON.stringify(loggedUserValue.data)
      );
      yield put(setLoggedUserDetails(loggedUserValue.data));
      toast.success("User Logged In Successfully");
    } else {
      // setUserEmailId()
      yield put(setUserInfoLoader(false));
      toast.error(loggedUserValue.err.data);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getLoggedUserInfo(params) {
  const { postData } = params;
  yield put(setUserInfoLoader(true));
  const userInfoUrl = `${process.env.REACT_APP_BASEURL}${GET_SUBSCRIBER_INFO_URL}`;
  const postParams = postData.split("_=");
  const data = qs.stringify({
    id: postParams[1],
  });
  try {
    const loggedUserValue = yield call(httpCall, {
      url: userInfoUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: postParams[0],
      },
      data,
    });
    if (loggedUserValue?.success) {
      loggedUserValue.data.token = postParams[0];
      yield put(setUserInfoLoader(false));
      yield put(setLoggedUserDetails(loggedUserValue.data));
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, postParams[0]);
      localStorage.setItem(
        COURIER_SERVICE_LOGIN,
        JSON.stringify(loggedUserValue.data)
      );
    } else {
      toast.error("Try Login Again");
      yield put(setUserInfoLoader(false));
    }
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong!");
  }
}

export function* sendOTP(params) {
  const { postData } = params;
  const userInfoUrl = `${process.env.REACT_APP_BASEURL}${SEND_OTP_URL}`;
  const data = qs.stringify(postData);
  try {
    const loggedUserValue = yield call(httpCall, {
      url: userInfoUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    });
    if (loggedUserValue?.success) {
      yield put(setUserEmailId(loggedUserValue.data));
      toast.success("Email Sent Successfully");
    } else {
      toast.error(loggedUserValue.err.data);
      yield put(setUserInfoLoader(false));
    }
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong!");
  }
}
export function* ResendOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const otpUrl = `${process.env.REACT_APP_BASEURL}${RESEND_OTP_URL}`;
    const otpEmailValues = yield call(httpCall, {
      url: otpUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (otpEmailValues?.success) {
      yield put(setEmailLoader(false));
      toast.success("Email Sent Successfully");
    }
  } catch (err) {
    console.log(err);
  }
}

export function* VerifyUserOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const verifyUserUrl = `${process.env.REACT_APP_BASEURL}${VERIFY_OTP_URL}`;
    const otpVerifyValues = yield call(httpCall, {
      url: verifyUserUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (otpVerifyValues?.success && !otpVerifyValues.data.error) {
      // yield put(setEmailLoader(false));
      yield put(setUserVerifyLoader(false));
      yield put(setUserVerificationError(false));
      yield put(setUserVerifyStatus(true));
    } else {
      yield put(setUserVerificationError(true));
      yield put(setUserVerifyLoader(false));
      toast.error(otpVerifyValues.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updateUserPassword(params) {
  try {
    // console.log(params);
    const { postData } = params;
    var data = qs.stringify(postData);
    const verifyUserUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_PASSWORD_URL}`;
    const passwordChangeValues = yield call(httpCall, {
      url: verifyUserUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (
      passwordChangeValues &&
      passwordChangeValues.success &&
      !passwordChangeValues.data.error
    ) {
      yield put(setPasswordChanges(true));
      toast.success("Password Changed Successfully");
    } else {
      yield put(setPasswordChanges(false));
      toast.error(passwordChangeValues.err.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchUserDetailsSaga() {
  // yield takeLatest('REGISTER_USER',RegisterUser);
  yield takeLatest("LOGIN_USER", LoginUser);
  yield takeLatest("User_INFO", getLoggedUserInfo);
  yield takeLatest("SEND_OTP", sendOTP);
  yield takeLatest("RESEND_OTP", ResendOtp);
  yield takeLatest("VERIFY_USER_OTP", VerifyUserOtp);
  yield takeLatest("UPDATE_PASSWORD", updateUserPassword);
}

export default function* UserDetailsSaga() {
  yield all([watchUserDetailsSaga()]);
}
