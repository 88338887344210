import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

const ContentLoader = ({ ...props }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...props}>
      <Spinner size="xl" color="teal.500" />
    </Box>
  );
};

export default ContentLoader;
