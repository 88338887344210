import React, { lazy, Suspense } from "react";
import RouteFallback from "./fallback.routes";
const Shopify = lazy(() => import("../channel/shopify"));
const DashboardPage = lazy(() => import("../pages/Dashboard.page"));
const WooCommerce = lazy(() => import("../channel/WooCommerce"));
const OpenCart = lazy(() => import("../channel/OpenCart"));
const B2cShipmentPage = lazy(() => import("../pages/B2cShipment.page"));
const ShippingPartnersPage = lazy(() =>
  import("../pages/Shippingpartners.page")
);
const PickuppointsPage = lazy(() => import("../pages/Pickuppoints.page"));
const BillingPage = lazy(() => import("../pages/Billing.page"));
const SettingsPage = lazy(() => import("../pages/Settings.page"));
const AWBstatusPage = lazy(() => import("../pages/AWBstatus.page"));
const CalculatePage = lazy(() => import("../pages/Calculator.page"));
const WeightDiscrepancy = lazy(() => import("../pages/WeightDiscrepancy.page"));
const RemittancePage = lazy(() => import("../pages/Remittance.page"));
const ChanelPage = lazy(() => import("../pages/Channel.page"));
const RoutesConfig = {
  privateRoute: [
    {
      path: "",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="private">
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/dashboard",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/manageinvoices",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="manageinvoices">
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/shipmentstatus",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="shipmentstatus">
          <B2cShipmentPage />
        </Suspense>
      ),
    },
    {
      path: "/shippingpartners",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="shippingpartners">
          <ShippingPartnersPage />
        </Suspense>
      ),
    },
    {
      path: "/pickuppoints",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="pickuppoints">
          <PickuppointsPage />
        </Suspense>
      ),
    },
    {
      path: "/billing",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="billing">
          <BillingPage />
        </Suspense>
      ),
    },
    {
      path: "/weightmanagement",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="weightmanagement">
          <WeightDiscrepancy />
        </Suspense>
      ),
    },
    {
      path: "/settings",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="settings">
          <SettingsPage />
        </Suspense>
      ),
    },
    {
      path: "/channel",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="chanel">
          <ChanelPage />
        </Suspense>
      ),
    },
    {
      path: "/channel/setup/shopify",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="chanel">
          <Shopify />
        </Suspense>
      ),
    },
    {
      path: "/channel/setup/wooCommerce",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="chanel">
          <WooCommerce />
        </Suspense>
      ),
    },
    {
      path: "/channel/setup/openCart",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="chanel">
          <OpenCart />
        </Suspense>
      ),
    },
    {
      path: "/status/:awbNumber",
      layoutType: false,
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />} key="awbstatus">
          <AWBstatusPage />
        </Suspense>
      ),
    },
    {
      path: "/calculatePrice",
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />} key="calculateprice">
          <CalculatePage />
        </Suspense>
      ),
    },
    {
      path: "/remittance",
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />} key="remittance">
          <RemittancePage />
        </Suspense>
      ),
    },
  ],
};

export default RoutesConfig;
