import React from "react";
import { Heading, VStack, RadioGroup, Radio } from "@chakra-ui/react";
import { CustomerNeed } from "../../../utils/utilityConstants";

const CustomerJobsForm = ({ onChange, value }) => {
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mb={4}>
        Which of the following best describe your/company need ?
      </Heading>
      <RadioGroup
        onChange={(value) => onChange("customerNeed", value)}
        value={value}
      >
        <VStack spacing={4} align="stretch">
          <Radio value={CustomerNeed.BRAND}>
            Direct to consumer brand (like lenskart, boAt, DaMENSCH, Bewakoof,
            etc.)
          </Radio>
          <Radio value={CustomerNeed.SOCIAL}>
            Selling on social media platform (like WhatsApp, Facebook,
            Instagram, etc.)
          </Radio>
          <Radio value={CustomerNeed.DOCUMENTS}>
            Sending documents or packages (like PhonePay ,BharatPay , Bills,
            etc.)
          </Radio>
          <Radio value={CustomerNeed.GIFTS}>
            Service firm sending gifts or office supplies to employee or
            customers (like laptop,mouse, etc.)
          </Radio>
          <Radio value={CustomerNeed.TRADERS}>
            Trader/Wholesaler/Reseller/B2B
          </Radio>
        </VStack>
      </RadioGroup>
    </>
  );
};

export default CustomerJobsForm;
