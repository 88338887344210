import { Suspense, lazy } from "react";
import RouteFallback from "./fallback.routes";
const HomeSettingPage = lazy(() => import("../pages/HomeSetting.page"));
const HomePage = lazy(() => import("../pages"));
const HomeRoutesConfig = {
  homeRoutes: [
    {
      path: "",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="home">
          <HomePage />
        </Suspense>
      ),
    },
    {
      path: "/settings",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="settings">
          <HomeSettingPage />
        </Suspense>
      ),
    },
  ],
};

export default HomeRoutesConfig;
