import { format, sub,parse } from "date-fns";
export function truncate(str, n, useWordBoundary) {
  if (str && str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

// Function for flatten an object and check there is any valid json string

export function isValidJsonString(value) {
  try {
    let obj = JSON.parse(value);
    return !!(!Array.isArray(obj) && typeof obj === "object");
  } catch (e) {
    return false;
  }
}
export function isValidObject(value) {
  try {
    return !!(!Array.isArray(value) && typeof value === "object");
  } catch (e) {
    return false;
  }
}
export function isJSON(data) {
  if (typeof data !== 'string') {
    return false;
  }
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}
export function getRowCodAmount(row, pathName) {
  if (row?.codAmount != null && Number(row?.codAmount) > 0) {
    return row?.codAmount;
  } else if (pathName === "B2B") {
    return row?.productDetails?.cod;
  } else if (isJSON(row?.productDetails)) {
    return JSON.parse(row?.productDetails).cod;
  } else {
    return 0;
  }
}
export function objectToFlattenObject(arr) {
  let finalres = arr.map((value) => {
    let final = { ...value };

    for (let key in value) {
      let isObject = isValidObject(value[key]);
      if (isObject) {
        let newObj = value[key];

        newObj =
          newObj != null &&
          Object.keys(newObj).reduce((obj, item) => {
            obj[`${key}_${item}`] = newObj[item];
            return obj;
          }, {});
        final = { ...final, ...newObj };
        delete final[key];
        delete final?.address_otherInfo;
      }
    }
    return final;
  });
  return finalres;
}
function flattenObject(obj, key) {
  let newObj = JSON.parse(obj);
  newObj = Object.keys(newObj).reduce((result, item) => {
    result[`${key}_${item}`] = newObj[item];
    return result;
  }, {});
  return newObj;
}
export function jsonStringToFlattenObject(arr) {
  function processValue(obj, key) {
    if (obj[key]) {
      let isJsonString = isValidJsonString(obj[key]);
      if (isJsonString) {
        let newObj = flattenObject(obj[key], key);
        obj = { ...obj, ...newObj };
        delete obj[key];
      } else if (!isJsonString) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => {
            obj = { ...obj, ...item, [key]: obj[key] };
          });
        }
      }
    }
    return obj;
  }

  let data = arr.map((value) => {
    let obj = { ...value };
    for (let key in value) {
      obj = processValue(obj, key);
    }
    return obj;
  });

  return data;
}

export function flattenArray(arr) {
  let data = arr.map((value) => {
    let obj = { ...value };

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        let isJsonString = isValidJsonString(value[key]);
        if (isJsonString) {
          let newObj = flattenObject(value[key], key);
          obj = { ...obj, ...newObj };
          delete obj[key];
        } else if (!isJsonString && Array.isArray(value[key])) {
          value[key].forEach((item) => {
            if (item?.statusName?.toLowerCase() === "delivered") {
              obj.deliveryDate = item?.updateDate;
            }
          });
        }
      }
    });

    return obj;
  });

  return data;
}

export const dateCalc = {
  hours24: format(
    sub(new Date(), {
      hours: 24,
    }),
    "yyyy-MM-dd"
  ),
  hours48: format(
    sub(new Date(), {
      hours: 48,
    }),
    "yyyy-MM-dd"
  ),
  week: format(
    sub(new Date(), {
      weeks: 1,
    }),
    "yyyy-MM-dd"
  ),
  last30Days: format(
    sub(new Date(), {
      months: 1,
    }),
    "yyyy-MM-dd"
  ),
  last60Days: format(
    sub(new Date(), {
      months: 2,
    }),
    "yyyy-MM-dd"
  ),
  last90Days: format(
    sub(new Date(), {
      months: 3,
    }),
    "yyyy-MM-dd"
  ),

  today: format(sub(new Date(), { days: 0 }), "yyyy-MM-dd"),
  yesterday: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
};

export function generateRandomString() {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const stringLength = 10;
  let randomString = "";

  for (let i = 0; i < stringLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters?.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}
export const formatValue = (value) => {
  const val = value && value?.toString();
  return val && val.startsWith(".") ? "0" + val : val;
};

export function isFormdataAvailable(availabledata, formdata) {
  return new Promise((resolve, reject) => {
    const { zone, priorityType, serviceType, serviceProvider } = formdata;
    if (availabledata?.[zone] && availabledata?.[zone][priorityType]) {
      const availableEntry = availabledata[zone][priorityType];
      if (
        availableEntry.serviceType === serviceType &&
        availableEntry.courier === serviceProvider
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
}
export const formatDate = (inputDate) => {
  if (inputDate !== null && inputDate !== undefined) {
    const parsedDate = parse(inputDate, "yyyy-MM-dd HH:mm:ss", new Date());
    return {
      date: format(parsedDate, "EEE MMM dd yyyy "),
      time: format(parsedDate, "hh:mm:ss "),
    };
  } else {
    return null;
  }
};
export function findUpdateDate(history, searchValue) {
  if (!history || !Array.isArray(history)) {
    return null;
  }

  function isSearchMatch(event, searchValue) {
    const eventStatus = String(event?.statusName)?.toLowerCase();
    const eventComment = String(event?.comment)?.toLowerCase();
    if (
      (searchValue === "Delivered" &&
        (eventStatus?.includes(searchValue.toLowerCase()) ||
          eventComment?.includes("return accepted"))) ||
      (searchValue === "Picked" &&
        (eventComment?.includes("picked up") ||
          eventStatus?.includes("picked") ||
          eventStatus?.includes("pickdone") ||
          eventStatus?.includes("manifested") ||
          eventStatus?.includes("pickup_complete") ||
          eventStatus?.includes("out_for_pickup")))
    ) {
      return true;
    }
    return false;
  }

  for (const event of history) {
    if (isSearchMatch(event, searchValue)) {
      if (event?.updateDate) {
        let updateDate;
        if(event?.updateDate.includes('-')&&event?.updateDate?.split('-')?.length>3){
          updateDate=event?.updateDate.split('-');
          updateDate=new Date(updateDate[0],updateDate[1],updateDate[2],updateDate[3])
        }else{
          updateDate=event?.updateDate;
        }
        return updateDate
      }
    }
  }

  return null;
}
export const XBeeformatDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = dateString.split(" ")[0].split("-");
  const day = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const year = dateParts[2];

  const dateObj = new Date(`${year}-${dateParts[1]}-${day}`);
  const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
    dateObj.getDay()
  ];

  return `${dayOfWeek} ${month} ${day} ${year}`;
};

export const isEmpty = (obj) => Object.keys(obj)?.length === 0;

export function convertToCm(value, unit) {
  if (unit.toLowerCase() === "feet") {
    return Number(value * 30.48);
  } else if (unit?.toLowerCase() === "inch") {
    return Number(value * 2.54);
  }
  return Number(value);
}
export function isDecimal(value) {
  return !Number.isInteger(value);
}

export  function getSuccessCount(response){
  const successCount=response.reduce((sum,res)=>{
    if(res.status===200 || res.statusCode===200){
      sum=sum+1;
    }
    return sum;
  },0)
  return successCount;
}