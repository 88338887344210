import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Heading,
  VStack,
  Text,
} from "@chakra-ui/react";

import { ShipmentServiceType } from "../../../utils/utilityConstants";

const ServiceTypeForm = ({ onChange, value: defaultValue }) => {
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mt={4}>
        What kind of service do you require?
        <Text as="span" color="red">
          *
        </Text>
      </Heading>

      <Text fontSize={"lg"} letterSpacing={1.5} ml={[0, 0, 5]} mb={4}>
        (Select All that are required)
      </Text>
      <CheckboxGroup
        id="shipmentType"
        onChange={(value) => onChange("shipmentType", value)}
        value={defaultValue}
      >
        <VStack spacing={4} align="stretch">
          {ShipmentServiceType?.map((item) => (
            <React.Fragment key={item?.value}>
              <Checkbox value={item?.value}>{item?.label}</Checkbox>
            </React.Fragment>
          ))}
        </VStack>
      </CheckboxGroup>
    </>
  );
};

export default ServiceTypeForm;
