import { createSlice } from "@reduxjs/toolkit";
export const UserDetailsSlice = createSlice({
  name: "UserDetailsSlice",
  initialState: {
    userEmailId: "",
    token: "",
    loggedUserDetails: "",
    userInfoLoading: false,
    isPasswordChanged: false,
    isOtpSendSuccessful: false,
    sendingOtp: false,
    isUserVerifyLoader: false,
    isUserVerified: false,
    isUserVerificationFailed: false,
  },
  reducers: {
    setUserEmailId: (state, action) => {
      state.userEmailId = action.payload;
    },
    setLoggedUserDetails: (state, action) => {
      state.loggedUserDetails = action.payload;
    },
    setUserInfoLoader: (state, action) => {
      state.userInfoLoading = action.payload;
    },
    setPasswordChanges: (state, action) => {
      state.isPasswordChanged = action.payload;
    },
    setEmailLoader: (state, action) => {
      state.sendingOtp = action.payload;
    },
    setUserVerifyLoader: (state, action) => {
      state.isUserVerifyLoader = action.payload;
    },
    setUserVerifyStatus: (state, action) => {
      state.isUserVerified = action.payload;
    },
    setUserVerificationError: (state, action) => {
      state.isUserVerificationFailed = action.payload;
    },
  },
});
export const {
  setUserName,
  setLoggedUserDetails,
  setUserInfoLoader,
  setPasswordChanges,
  setEmailLoader,
  setUserVerifyLoader,
  setUserVerifyStatus,
  setUserVerificationError,
  setUserEmailId,
} = UserDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getLoggedUser = (state) =>
  state.UserDetailsSlice.loggedUserDetails;

export const getUserLoader = (state) => state.UserDetailsSlice.userInfoLoading;

export const getOtpVerificationStatus = (state) =>
  state.UserDetailsSlice.isOtpVerified;

export const getEmailLoader = (state) => state.UserDetailsSlice.sendingOtp;

export const getUserEmailId = (state) => state.UserDetailsSlice.userEmailId;

export const getUserVerifyLoader = (state) =>
  state.UserDetailsSlice.isUserVerifyLoader;

export const getUserVerifyStatus = (state) =>
  state.UserDetailsSlice.isUserVerified;

export const getUserVerificationError = (state) =>
  state.UserDetailsSlice.isUserVerificationFailed;

export const getPasswordChanges = (state) =>
  state.UserDetailsSlice.isPasswordChanged;
///we can also write thunks by hand , which may contain both sync and async logic

export default UserDetailsSlice.reducer;
