import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { FaSpinner } from "react-icons/fa";
const FallbackRoute = () => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      w={"100%"}
    >
      <Flex>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <FaSpinner
          style={{
            position: "absolute",
            marginTop: "8px",
            marginLeft: "7px",
            fontSize: "2em",
          }}
        />
      </Flex>
    </Flex>
  );
};
export default FallbackRoute;
