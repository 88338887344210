import { fork, all } from "redux-saga/effects";
import UserDetailsSaga from "./UserDetailsSaga";
import BookingDetailsSaga from "./BookingDetailsSaga";
import CustomerDetailsSage from "./CustomerDetailsSaga";
import PaymentDetailsSaga from "./PaymentDetailsSaga";
import DashboardDetailsSaga from "./DashboardDetailsSaga";
import DiscrepancyDetailsSaga from "./DiscrepancyDetailsSaga";
import TransactionDetailsSaga from "./TransactionDetailsSaga";
import CustomerSettingsSaga from "./CustomerSettingsSaga";
import ExcelDataSaga from "./ExcelDownloadSaga";
export default function* rootSaga() {
  yield all([
    fork(UserDetailsSaga),
    fork(BookingDetailsSaga),
    fork(CustomerDetailsSage),
    fork(PaymentDetailsSaga),
    fork(DashboardDetailsSaga),
    fork(DiscrepancyDetailsSaga),
    fork(TransactionDetailsSaga),
    fork(CustomerSettingsSaga),
    fork(ExcelDataSaga),
  ]);
}
