import { Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,Button } from "@chakra-ui/react";
import React, { useEffect } from "react";

function IsB2bOrB2cModal({serviceName}) {
   
    const OverlayTwo = () => (
      <ModalOverlay
        bg='none'
        backdropFilter='auto'
        backdropInvert='80%'
        backdropBlur='2px'
      />
    )
  
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState();

    useEffect(()=>{
        setTimeout(()=>{
            setOverlay(<OverlayTwo />);
            onOpen()
        },5000);

       },[])
    return (
      <>
        
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={'xl'}>
          {overlay}
          <ModalContent>
            <ModalHeader>{`${serviceName} Account is not Active`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{`Contact on 7678465154 to continue ${serviceName} service`} </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        
      </>
    )
  }

  export default IsB2bOrB2cModal;