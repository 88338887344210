import React, { useState, useEffect } from "react";
import { Button, Image, Flex } from "@chakra-ui/react";
import Webcam from "react-webcam";
import { MdCameraAlt } from "react-icons/md";
import UploadSelfie from "./UploadSelfie";
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const WebcamCapture = ({ onChange, file }) => {
  const [image, setImage] = useState(file);
  const getBase64 = (fileUrl) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileUrl);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleFileInputChange = async (e) => {
    let selectedFile = e.target.files[0];
    setImage(await getBase64(selectedFile));
    onChange(await getBase64(selectedFile));
  };
  //-----------------
  const webcamRef = React.useRef(null);
  useEffect(() => {
    if (file) {
      setImage(file);
    }
  }, [file]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    onChange(imageSrc);
  }, []);

  const imagePath =
    image && !image.includes("data:image/jpeg;base64")
      ? `data:image/jpeg;base64,${image}`
      : image;
  return (
    <div className="webcam-container">
      {!image ? (
        <Flex flexDir={"column"} gap={5}>
          <Webcam
            audio={false}
            width={720}
            height={1280}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          <Flex
            gap={4}
            alignItems={"center"}
            justifyContent={"center"}
            flexDir={["column", "row"]}
          >
            <Button
              w={220}
              leftIcon={<MdCameraAlt />}
              variant="outline"
              colorScheme="blue"
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
            >
              Take Selfie
            </Button>
            {/* <UploadSelfie onChange={handleFileInputChange} file={file} /> */}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDir={"column"} gap={5}>
          <Image
            src={imagePath}
            alt={"selfi"}
            aspectRatio={"12/6"}
            objectFit={"contain"}
            cursor="pointer"
            onClick={() => {
              const newTab = window.open("", "_blank");
              newTab.document.body.innerHTML = `<img src="${imagePath}" alt="Image" width="100%" height="100%">`;
            }}
          />
          <Flex
            gap={4}
            alignItems={"center"}
            justifyContent={"center"}
            flexDir={["column", "row"]}
          >
            <Button
              leftIcon={<MdCameraAlt />}
              variant="outline"
              colorScheme="blue"
              w={220}
              onClick={(e) => {
                e.preventDefault();
                setImage("");
                onChange("");
              }}
            >
              Retake Selfie
            </Button>
            {/* <UploadSelfie onChange={handleFileInputChange} file={file} /> */}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default WebcamCapture;
