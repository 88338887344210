import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, SimpleGrid, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { SERVICEMODETYPE } from "../../utils/utilityConstants";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import { useNavigate } from "react-router-dom";
import { formatValue } from "../../utils/utilsFunction";

import WeightFormControl from '../BookShipment/BookingFormField/WeightFormControl';
import CodAmountFormControl from '../BookShipment/BookingFormField/CodAmountFormControl';
import LengthFormControl from '../BookShipment/BookingFormField/LengthFormControl';
import WidthFormControl from '../BookShipment/BookingFormField/WidthFormControl';
import HeightFormControl from '../BookShipment/BookingFormField/HeightFormControl';
import ServiceModeControl from '../BookShipment/BookingFormField/ServiceModeControl';
import {
  CustomDeliveryPincodeFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
} from "./CalculatorField";
import { getIsRouteB2bOrB2c } from "../../redux/slices/ComponentFunctionSlice";
const Calculatepage = ({ close }) => {
  const loggedUser = useSelector(getLoggedUser);
  const isB2bOrB2c = useSelector(getIsRouteB2bOrB2c);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [weight, setWeight] = useState();

  function onSubmit(values) {
    const params = {
      ...values,
      codAmount:
        values && values?.paymentMode === "COD"
          ? Number(values?.codAmount)
          : undefined,
      weight: formatValue(values?.weight),
      height: formatValue(values?.height),
      length: formatValue(values?.length),
      width: formatValue(values?.width),
      customerId: loggedUser.customerId,
    };
    dispatch({
      type: "CALCULATE_PRICE",
      payload: params,
      pathName: isB2bOrB2c,
    });
    navigate("/b2c/calculatePrice");
    close();
  }
  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <ServiceModeControl
            controlId={"serviceMode"}
            label="Service Mode"
            errors={errors}
            control={control}
            options={SERVICEMODETYPE}
          />
          <CustomPincodeFormControl
            controlId="pickUpPincode"
            label="Pickup Pincode"
            placeholder="PickUp Pincode"
            register={register}
            errors={errors}
          />
          <CustomDeliveryPincodeFormControl
            controlId="deliveryPincode"
            label="Delivery Pincode"
            placeholder="Delivery Pincode"
            register={register}
            errors={errors}
          />
          <PaymentModeControl
            controlId="paymentMode"
            label="Payment Mode"
            control={control}
            errors={errors}
            isCOD={true}
          />
          {watch("paymentMode") === "COD" && (
            <CodAmountFormControl register={register} errors={errors} />
          )}
          <WeightFormControl
            register={register}
            errors={errors}
            setWeight={setWeight}
          />

          <LengthFormControl
            register={register}
            errors={errors}
            setLength={setLength}
          />
          <HeightFormControl
            register={register}
            errors={errors}
            setHeight={setHeight}
          />
          <WidthFormControl
            register={register}
            errors={errors}
            setWidth={setWidth}
          />
        </SimpleGrid>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
        >
          Submit
        </Button>
        <Box>
          Volumetric Weight : {""}
          {(length && width && height) != null
            ? (length * width * height) / 5000
            : ""}
          kg or{" "}
          {(length && width && height) != null
            ? (length * width * height) / 5
            : ""}
          gram
        </Box>
      </form>
    </Box>
  );
};
export default Calculatepage;
