import { useMediaQuery, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import Navbar from "../containers/navbar";
import Sidebar from "../components/sidebar";
import { COLOR } from "../utils/constant";
import { Outlet } from "react-router-dom";
import Whatsapp from "../pages/Whatsapp";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUser } from "../redux/slices/UserDetailsSlice";
import { useLocation } from "react-router-dom";
import { setIsRouteB2bOrB2c } from "../redux/slices/ComponentFunctionSlice";
import { getCustomerType } from "../redux/slices/CustomerDetailsSlice";
import {getB2bConfig,getB2cConfig,getConfigLoading} from '../redux/slices/CustomerSettingsSlice';
import IsB2bOrB2cModal from "../components/AlertModel/IsB2bOrB2cModal";

const PrivateLayout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const b2bConfig=useSelector(getB2bConfig);
  const b2cConfig=useSelector(getB2cConfig);
  const isConfigLoading=useSelector(getConfigLoading)
  const router = useLocation();
  const customerType = useSelector(getCustomerType);
  let pathName = router?.pathname.split("/")[1].toUpperCase();
  const [isGreterThan768] = useMediaQuery("(min-width: 769px)");
  const [isLessThan1024] = useMediaQuery("(max-width: 1024px)");
  React.useEffect(() => {
    dispatch(setIsRouteB2bOrB2c(pathName));
    if (loggedUser?.customerId) {
      const params = {
        id: loggedUser?.customerId,
        shipmentType: pathName,
      };
      dispatch({
        type: "GET_CUSTOMER_TYPE",
        payload: { id: loggedUser?.customerId },
      });
      dispatch({
        type: "GET_CUSTOMER_SETTING",
        customerId: loggedUser?.customerId,
      });
      dispatch({ type: "GET_CUSTOMER_INFO", payload: params });
      dispatch({
        type: "GET_PICKUP_LOCATION",
        postData: {
          page: 1,
          filter: {
            customerId: loggedUser?.customerId,
            addressCategory: "pickup",
          },
        },
      });
    }
    if(pathName==='B2B' && Object.keys(b2bConfig).length===0 &&!isConfigLoading){
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2B'}  });
    dispatch(setIsRouteB2bOrB2c('B2B'));
    }
    if(pathName==='B2C' && Object.keys(b2cConfig).length===0 &&!isConfigLoading){
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2C'}  })
    dispatch(setIsRouteB2bOrB2c('B2C'));
    }
    return () => {
      dispatch(setIsRouteB2bOrB2c(""));
    };
  }, [loggedUser?.customerId]);
  return (
    <>
      {(!customerType?.isB2bActive && pathName === "B2B") ||
      (!customerType?.isB2cActive && pathName === "B2C") ? (
        <IsB2bOrB2cModal
          serviceName={
            !customerType?.isB2bActive && pathName === "B2B" ? "B2B" : "B2C"
          }
        />
      ) : (
        ""
      )}
      <Grid
        h={"100vh"}
        maxW={"100vw"}
        templateRows="repeat(12, 1fr)"
        templateColumns="repeat(23, 1fr)"
      >
        <GridItem bg={COLOR.blue} rowSpan={1} colSpan={23}>
          <Navbar />
        </GridItem>
        {isGreterThan768 && (
          <GridItem bg={COLOR.blue} rowSpan={11} colSpan={1}>
            <Sidebar />
          </GridItem>
        )}
        <GridItem
          bg={"whiteAlpha.100"}
          rowSpan={11}
          colSpan={isGreterThan768 ? 22 : 23}
          overflow="auto"
          p={5}
        >
          <Outlet />
          {!isLessThan1024 && <Whatsapp />}
        </GridItem>
      </Grid>
    </>
  );
};

export default PrivateLayout;
