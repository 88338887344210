import { createSlice } from "@reduxjs/toolkit";
export const CustomerSettingsSlice = createSlice({
  name: "CustomerSettingsSlice",
  initialState: {
    customerSetting: "",
    isCustomerSettingLoading: false,
    b2bConfig:{},
    b2cConfig:{},
    isLoading:false
  },
  reducers: {
    setCustomerSetting: (state, action) => {
      state.customerSetting = action.payload;
    },
    setIsCustomerSettingLoading: (state, action) => {
      state.isCustomerSettingLoading = action.payload;
    },
    setManifestSetting: (state, action) => {
      state.manifestSetting = action.payload;
    },
    setB2BConfig:(state,action)=>{
      state.b2bConfig=action.payload;
    },
    setB2CConfig:(state,action)=>{
      state.b2cConfig=action.payload;
    },
    setConfigLoading:(state,action)=>{
      state.isLoading=action.payload;
    }
  },
});
export const {
  setCustomerSetting,
  setIsCustomerSettingLoading,
  setManifestSetting,
  setB2BConfig,
  setB2CConfig,
  setConfigLoading
} = CustomerSettingsSlice.actions;

export const getCustomerSetting = (state) =>
  state.CustomerSettingsSlice.customerSetting;
export const getIsCustomerSettingLoading = (state) =>
  state.CustomerSettingsSlice.isCustomerSettingLoading;
export const getManifestSetting = (state) =>
  state.CustomerSettingsSlice.manifestSetting;
export const getB2bConfig = (state) =>
  state.CustomerSettingsSlice.b2bConfig;
export const getB2cConfig = (state) =>
  state.CustomerSettingsSlice.b2cConfig;
export const getConfigLoading=(state)=>
  state.CustomerSettingsSlice.isLoading;

export default CustomerSettingsSlice.reducer;
