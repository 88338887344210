import React, { useEffect } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Heading,
  Stack,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ProductCategoryData } from "../../../utils/utilityConstants";
const ProductCategoryForm = ({ onChange, value: defaultValue }) => {
  const [input, setInput] = React.useState("");
  const [productCategoryValue, setProductCategoryValue] = React.useState(
    defaultValue || []
  );
  React.useEffect(() => {
    const hasOthers = productCategoryValue?.includes(ProductCategoryData.OTHER);
    if (hasOthers && input?.length > 1) {
      onChange("productCategory", [
        ...productCategoryValue?.filter(
          (item) => item !== ProductCategoryData?.OTHER
        ),
        input,
      ]);
    } else {
      onChange("productCategory", productCategoryValue);
    }
  }, [input, productCategoryValue]);
  const isError = input === "";
  const handleProductCategory = (value) => {
    setProductCategoryValue(value);
  };
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800">
        Please choose the product category you deal in.
        <Text as="span" color="red">
          *
        </Text>
      </Heading>
      <Text fontSize={"lg"} letterSpacing={1.5} ml={[0, 0, 5]} mb={4}>
        (Select as many as applicable)
      </Text>
      <CheckboxGroup
        colorScheme="blue"
        id="productCategory"
        value={productCategoryValue}
        onChange={handleProductCategory}
      >
        <Stack spacing={2} direction={"column"}>
          <Checkbox value={ProductCategoryData.CLOTHS}>Cloths/Dresses</Checkbox>
          <Checkbox value={ProductCategoryData.ELECTRONICS}>
            Mobiles/Laptop/Television
          </Checkbox>
          <Checkbox value={ProductCategoryData.ELECTRONIC_PARTS}>
            Electronic items and their accessories (like earphones, mouse,
            speakers, TV parts, etc.)
          </Checkbox>
          <Checkbox value={ProductCategoryData.PACKAGED_FOOD}>
            Packaged Food Items
          </Checkbox>
          <Checkbox value={ProductCategoryData.HOME_KITCHEN}>
            Home/Kitchen/Pets ( kitchen Items, Bed Sheet,blankets, etc.)
          </Checkbox>
          <Checkbox value={ProductCategoryData.HEALTH_BEAUTY}>
            Health Products/Beauty Products (like Face cream,Mask,Chawanprash ,
            etc.)
          </Checkbox>
          <Checkbox value={ProductCategoryData.SPORTS_FITNESS}>
            Sports/Fitness/Bags/Luggage
          </Checkbox>
          <Checkbox value={ProductCategoryData.KIDS}>
            Toys/Baby Products/Kids Fashion
          </Checkbox>
          <Checkbox value={ProductCategoryData.INDUSTRIAL}>
            Car/Motorbike/Industrial Parts
          </Checkbox>
          <Checkbox value={ProductCategoryData.OTHER}>Others</Checkbox>
        </Stack>
      </CheckboxGroup>
      {productCategoryValue?.includes(ProductCategoryData.OTHER) && (
        <FormControl isInvalid={isError}>
          <Input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          {!isError && <FormErrorMessage>this is required.</FormErrorMessage>}
        </FormControl>
      )}
    </>
  );
};

export default ProductCategoryForm;
