import { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  InputGroup,
} from "@chakra-ui/react";
import { MdDelete, MdImage } from "react-icons/md";

const FileUpload = ({ register, accept, multiple, ...props }) => {
  const [file, setFile] = useState();
  const inputRef = useRef(null);
  const { ref, ...rest } = register;
  const handleClick = () => inputRef.current?.click();
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleDelete = () => {
    inputRef.current.value = null;
    setFile(null);
    ref(inputRef.current);
  };
  return (
    <Flex flexDir={["column", "column", "row"]} gap={[2, 1]}>
      <InputGroup
        w={160}
        onClick={handleClick}
        onChange={(e) => handleChange(e)}
        {...props}
      >
        <input
          type={"file"}
          multiple={multiple || false}
          hidden
          accept={accept}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          {...rest}
        />
        <Button
          w={160}
          leftIcon={<MdImage />}
          variant="outline"
          colorScheme="blue"
          borderRadius={"sm"}
          {...props}
        >
          Upload
        </Button>
      </InputGroup>
      {file ? (
        <ButtonGroup
          onClick={handleDelete}
          colorScheme="red"
          w={160}
          isAttached
          variant="outline"
        >
          <IconButton borderRight={"none"} icon={<MdDelete />} />
          <Button borderRadius={"sm"}>{file?.name?.slice(0, 12)}</Button>
        </ButtonGroup>
      ) : null}
    </Flex>
  );
};

export default FileUpload;
