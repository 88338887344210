import React, { useState } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import FileUpload from "../../../components/FileUpload";
import Selfi from "../../CustomerInfoModal/CustomerForm/Selfie";
import { getCustomerType } from "../../../redux/slices/CustomerDetailsSlice";
import { CustomerType } from "../../../utils/utilityConstants";
const KycDocumentForm = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const customerInfo = useSelector(getCustomerType);
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice?.length);
      for (let i = 0; i < slice?.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  const onSubmit = async (values) => {
    let selfImage = image?.split(",")[1];
    if (selfImage) {
      const contentType = "image/png";
      const blob = b64toBlob(selfImage, contentType);
      const docInfoParams = {
        docData: {
          customerId: customerInfo.customerId,
          doc: selfImage,
          docType: "selfi",
          comment: "unverified",
        },
        file: blob,
      };
      dispatch({ type: "PUT_KYC_DOCS", postData: docInfoParams });
    }
    if (values.logo?.length >= 0) {
      const docInfoParams = {
        docData: {
          customerId: customerInfo.customerId,
          docType: "logo",
          comment: "unverified",
        },
        file: values.logo[0],
      };
      dispatch({ type: "PUT_KYC_DOCS", postData: docInfoParams });
    }
    if (values.gst?.length > 0) {
      const docInfoParams = {
        docData: {
          customerId: customerInfo.customerId,
          docType: "gst",
          comment: "unverified",
        },
        file: values.gst[0],
      };
      dispatch({ type: "PUT_KYC_DOCS", postData: docInfoParams });
    }
    if (values.aadharFront?.length >= 0) {
      const docInfoParams = {
        docData: {
          customerId: customerInfo.customerId,
          docType: "aadhar_front",
          comment: "unverified",
        },
        file: values.aadharFront[0],
      };
      dispatch({ type: "PUT_KYC_DOCS", postData: docInfoParams });
    }
    if (values.aadharBack?.length >= 0) {
      const docInfoParams = {
        docData: {
          customerId: customerInfo.customerId,
          docType: "aadhar_back",
          comment: "unverified",
        },
        file: values.aadharBack[0],
      };
      dispatch({ type: "PUT_KYC_DOCS", postData: docInfoParams });
    }
  };
  const validateGst = (value) => {
    // for (const file of Array.from(value)) {
    //   const fskb = file.size;
    //   const MAX_FILE_SIZE = 61440;
    //   if (fskb > MAX_FILE_SIZE) {
    //     return "Max file size 60kb";
    //   }
    // }
    return true;
  };
  const validateFiles = (value) => {
    if (value.length < 1) {
      return "It is required";
    }
    return validateGst(value);
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading as="h2" size={"lg"} textAlign={"center"} color="blackAlpha.800">
        Almost done! Please upload your document to complete KYC
      </Heading>
      <FormControl isInvalid={!image} w={"full"}>
        <FormLabel textAlign={"center"} fontSize={[12, 14]}>
          (Make sure your photos are not blurry, enough light and not wearing a
          mask, eye glasses )
        </FormLabel>
        <Selfi onChange={setImage} />
        {!image && (
          <FormErrorMessage w={220} mx={"auto"}>
            Image is required
          </FormErrorMessage>
        )}
      </FormControl>
      <SimpleGrid columns={[1, 2]} justifyItems={"center"} rowGap={2}>
        {customerInfo.type === CustomerType.BUSINESS && (
          <FormControl isInvalid={!!errors.logo}>
            <FormLabel>{"Logo"}</FormLabel>
            <FileUpload
              accept={"image/*"}
              multiple={false}
              register={register("logo")}
            />
            <FormErrorMessage>
              {errors.logo && errors?.logo.message}
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl isInvalid={!!errors.aadharFront} isRequired>
          <FormLabel>{"Aadhar Front"}</FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("aadharFront", {
              validate: validateFiles,
            })}
          />
          <FormErrorMessage>
            {errors.aadharFront && errors?.aadharFront.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.aadharBack} isRequired>
          <FormLabel>Aadhar Back</FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("aadharBack", { validate: validateFiles })}
          />
          <FormErrorMessage>
            {errors?.aadharBack && errors?.aadharBack.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.gst}>
          <FormLabel>
            GST
            <Text fontSize={[12, 14]}>
              {/* (Upload GST to get the best Services and price) */}
            </Text>
          </FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("gst", { validate: validateGst })}
          />
          <FormErrorMessage>
            {errors.gst && errors?.gst.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Button borderRadius={"sm"} mt={4} colorScheme="blue" type="submit">
        Save
      </Button>
    </form>
  );
};

export default KycDocumentForm;
