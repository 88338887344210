export const CustomerType = {
  NORMAL: "Normal",
  BUSINESS: "Business",
  FRANCHISE: "Franchise",
  Other: "Other",
};

export const CustomerNeed = {
  BRAND: "brand",
  SOCIAL: "social",
  DOCUMENTS: "documents",
  GIFTS: "gifts",
  TRADERS: "trader",
};

export const CustomerSellPoint = {
  MARKET_PLACE: "marketplace",
  WEBSITE: "website",
  SOCIAL: "social",
  RETAILS: "retails",
};

export const ProductCategoryData = {
  CLOTHS: "cloths",
  ELECTRONICS: "electronics",
  ELECTRONIC_PARTS: "electronicParts",
  PACKAGED_FOOD: "packagedFood",
  HOME_KITCHEN: "homeKitchen",
  HEALTH_BEAUTY: "healthBeauty",
  SPORTS_FITNESS: "sportsFitness",
  KIDS: "kids",
  INDUSTRIAL: "industrialParts",
  OTHER: "other",
};

export const ProductType = [
  {
    value: "Documents",
    label: "Documents",
  },
  {
    value: "Books",
    label: "Books",
  },
  {
    value: "Cloths",
    label: "Cloths",
  },
  {
    value: "Gifts",
    label: "Gifts",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    value: "Food",
    label: "Food",
  },

  {
    value: "Medicines",
    label: "Medicines",
  },
  {
    value: "Household",
    label: "Household",
  },
  {
    value: "Other",
    label: "Other",
  },
];
export const ShipmentServiceType = [
  {
    value: "isB2bAccount",
    label: "B2B shipment ( Business to Business )",
  },
  {
    value: "isB2cAccount",
    label: "B2C shipment ( Business to Customer )",
  },
  { value: "isHyperLocal", label: "Hyperlocal shipment" },
  {
    value: "isInternationalShipment",
    label: "International shipment",
  },
];
export const PackagingType = [
  {
    value: "Envelope",
    label: "Envelope",
  },
  {
    value: "Parcel Bag",
    label: "Parcel Bag",
  },
  {
    value: "Parcel Box",
    label: "Parcel Box",
  },
  {
    value: "Not Packed",
    label: "Not Packed",
  },
];
export const ParcelQty = [
  {
    value: "1 Parcel",
    label: "1 Parcel",
  },
  {
    value: "2 Parcel",
    label: "2 Parcel",
  },
  {
    value: "3 Parcel",
    label: "3 Parcel",
  },
  {
    value: "4 Parcel",
    label: "4 Parcel",
  },
  {
    value: "5 Parcel",
    label: "5 Parcel",
  },
  {
    value: "6 Parcel",
    label: "6 Parcel",
  },
  {
    value: "7 Parcel",
    label: "7 Parcel",
  },
  {
    value: "8 Parcel",
    label: "8 Parcel",
  },
  {
    value: "9 Parcel",
    label: "9 Parcel",
  },
  {
    value: "10 Parcel",
    label: "10 Parcel",
  },
  {
    value: "Bulk Parcel",
    label: "Bulk Parcel",
  },
];


export const SERVICEMODE = {
  FW: "Forward",
  RV: "Reverse",
};

export const B2BSERVICEMODE = [{ label: "Forward", value: "FORWARD" }];
export const B2BSERVICEMODETYPE = [{ label: "Forward", value: "FORWARD" }];
export const SERVICEMODETYPE = [
  { label: "Forward", value: "FW" },
  { label: "Reverse", value: "RV" },
];

export const PGPAYMENTMODE = {
  CREDIT: "CC",
  DEBIT: "DC",
  EBT: "NB",
  UPI: "UPI",
  WALLET: "WALLET",
};

export const SERVICETYPES = {
  SURFACE: "SURFACE",
  EXPRESS: "EXPRESS",
};

export const QUIXPAYMENTMODE = {
  CREDIT: "credit",
  DEBIT: "debit",
  EBT: "ebt",
  CASH: "cash",
  PCHECK: "pcheck",
  ECHECK: "echeck",
  UPI: "upi",
  QRCODE: "qrcode",
  MOBILEWALLET: "mobileWallet",
  QUIXWALLET: "quixWallet",
  QUIXCASHBACK: "quixCashback",
};
export const CompanyType = [
  {
    value: "pts",
    label: "Partnership",
  },
  {
    value: "llp",
    label: "Limited Liability Partnership",
  },
  {
    value: "pvt",
    label: "Private Limited Company",
  },
  {
    value: "plc",
    label: "Public Limited Company",
  },
  {
    value: "opc",
    label: "One Person Company",
  },
  {
    value: "sec",
    label: "Section 8 Company",
  },

  {
    value: "ngo",
    label: "Non-Government Organization",
  },
  {
    value: "prop",
    label: "Proprietorship",
  },
  {
    value: "na",
    label: "Not Applicable",
  },
];
export const manifestSettingData = [
  {
    name: 'quixgoLogo',
    title: 'Quixgo logo',
    description: 'Hide/Show Quixgo Logo',
  },
  {
    name: 'customerLogo',
    title: 'Display Logo',
    description: 'Hide/Show Display Logo',
  },
  {
    name: 'invoiceValue',
    title: 'Invoice Value',
    description: 'Hide/Show Invoice Value',
  },
  {
    name: 'returnAddress',
    title: 'Return Address',
    description: 'Hide/Show Return Address',
  },
  {
    name: 'sellerContactDetails',
    title: 'Seller Information',
    description: 'Hide/Show Seller Contact Details',
  },
  {
    name: 'products',
    title: 'Products Information',
    description: 'Hide/Show Products Details',
  }
  // ,
  // {
  //   name: 'weight',
  //   title: 'Weight',
  //   description: 'Hide/Show Weight',
  // },
  // {
  //   name: 'consigneeMobile',
  //   title: 'consignee Mobile',
  //   description: 'Hide/Show consignee Mobile',
  // },
];
export const Zone = [
  { label: "Within City", value: "city" },
  { label: "Metro to Metro", value: "metro" },
  { label: "Within Zone", value: "zone" },
  { label: "Rest of India", value: "india" },
  { label: "Extended Location", value: "special" },
];
