import { useState, useEffect, useRef } from "react";

const useDraggable = (setIsDragging) => {
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const draggableElementRef = useRef(null);

  useEffect(() => {
    const handleDragStart = (e) => {
      if (!draggableElementRef.current || e.target.tagName === "svg") return;

      setOffsetX(
        e.clientX - draggableElementRef.current.getBoundingClientRect().left
      );
      setOffsetY(
        e.clientY - draggableElementRef.current.getBoundingClientRect().top
      );

      setIsDragging(true);

      e.preventDefault();
      e.stopPropagation();

      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    };

    const handleDrag = (e) => {
      if (!draggableElementRef.current) return;

      let x = e.clientX - offsetX;
      let y = e.clientY - offsetY;

      x = Math.max(
        0,
        Math.min(x, window.innerWidth - draggableElementRef.current.clientWidth)
      );
      y = Math.max(
        0,
        Math.min(
          y,
          window.innerHeight - draggableElementRef.current.clientHeight
        )
      );

      draggableElementRef.current.style.left = x + "px";
      draggableElementRef.current.style.top = y + "px";
    };

    const handleDragEnd = () => {
      setIsDragging(false);

      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };

    draggableElementRef.current?.addEventListener("mousedown", handleDragStart);
    const draggableRef=draggableElementRef.current;
    return () => {
      draggableRef?.removeEventListener(
        "mousedown",
        handleDragStart
      );
    };
  }, [offsetX, offsetY, setIsDragging]);

  return draggableElementRef;
};

export default useDraggable;
