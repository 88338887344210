import { takeLatest, all, call, put, select } from "redux-saga/effects";
import {
  httpCall,
  SAVE_DEFAULT_ADDRESS_URL,
  SAVE_COURIER_SETTINGS_URL,
  GET_COURIER_SETTINGS_URL,
  SAVE_PACKAGE_VOLUME_URL,
  SAVE_MANIFEST_SETTING_URL,
  SAVE_FULFILMENT_SETTING_URL,
  SAVE_DEFAULT_BANKACCOUNT_URL,
  GET_APP_CONFIG_B2C_URL,
  GET_APP_CONFIG_B2B_URL,
  SAVE_AUTO_BOOKING_URL
} from "../../utils/api";

import { toast } from "react-toastify";
import {
  setCustomerSetting,
  setIsCustomerSettingLoading,
  setB2BConfig,
  setB2CConfig,
  setConfigLoading
} from "../slices/CustomerSettingsSlice";
import { fetchPickupLocation } from "./BookingDetailsSaga";
import { getIsRouteB2bOrB2c } from "../slices/ComponentFunctionSlice";

export function* getCustomerSetting(params) {
  yield put(setIsCustomerSettingLoading(true));

  const { customerId } = params;
  const getCustomerSettingUrl = `${process.env.REACT_APP_BASEURL}${GET_COURIER_SETTINGS_URL}/${customerId}`;
  try {
    const customerSetting = yield call(httpCall, {
      url: getCustomerSettingUrl,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (customerSetting?.success) {
      yield put(setCustomerSetting(customerSetting.data));
      yield put(setIsCustomerSettingLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setIsCustomerSettingLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsCustomerSettingLoading(false));
  }
}
export function* putCourierSettings(params) {
  try {
    const putCourierUrl = `${process.env.REACT_APP_BASEURL}${SAVE_COURIER_SETTINGS_URL}`;
    const { postData } = params;
    const refreshDataParams = JSON.parse(postData);
    const courierUpdateResponse = yield call(httpCall, {
      url: putCourierUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (courierUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: refreshDataParams?.customerId,
      });
    } else {
      toast.error(courierUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putPackageVolume(params) {
  try {
    const getPackageVolumeUrl = `${process.env.REACT_APP_BASEURL}${SAVE_PACKAGE_VOLUME_URL}`;
    const { postData } = params;
    const PackageVolumeResponse = yield call(httpCall, {
      url: getPackageVolumeUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (PackageVolumeResponse?.success) {
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(PackageVolumeResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putManifestSetting(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  try {
    const getPackageVolumeUrl = `${process.env.REACT_APP_BASEURL}${SAVE_MANIFEST_SETTING_URL}/${pathName}`;
    const { postData } = params;
    const ManifestSettingResponse = yield call(httpCall, {
      url: getPackageVolumeUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (ManifestSettingResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });

      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
    } else {
      toast.error(ManifestSettingResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putFulfillmentSetting(params) {
  try {
    const getUrl = `${process.env.REACT_APP_BASEURL}${SAVE_FULFILMENT_SETTING_URL}`;
    const { postData } = params;
    const FulfilmentSettingResponse = yield call(httpCall, {
      url: getUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (FulfilmentSettingResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });

      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
    } else {
      toast.error(FulfilmentSettingResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putDefaultAddress(params) {
  try {
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${SAVE_DEFAULT_ADDRESS_URL}`;
    const { postData } = params;
    const DefaultAddressUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (DefaultAddressUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
      yield fetchPickupLocation({
        type: "GET_PICKUP_LOCATION",
        postData: {
          limit: 10,
          page: 1,
          filter: {
            customerId: postData?.customerId,
            addressCategory: "pickup",
          },
        },
      });
    } else {
      toast.error(DefaultAddressUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putDefaultBank(params) {
  try {
    const getBankUrl = `${process.env.REACT_APP_BASEURL}${SAVE_DEFAULT_BANKACCOUNT_URL}`;
    const { postData } = params;
    const DefaultBankUpdateResponse = yield call(httpCall, {
      url: getBankUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (DefaultBankUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
    } else {
      toast.error(DefaultBankUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}

export function* getAppConfig(params) {
  try {
    const { postData:{
      shipmentType
    } } = params;
    yield put(setConfigLoading(true));
    const getBankUrl = `${process.env.REACT_APP_BASEURL}${shipmentType==='B2C'?GET_APP_CONFIG_B2C_URL:GET_APP_CONFIG_B2B_URL}`;
    const appConfigResponse = yield call(httpCall, {
      url: getBankUrl,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (appConfigResponse?.success) {
      yield put(shipmentType==='B2C'?setB2CConfig(appConfigResponse.data):setB2BConfig(appConfigResponse.data));
      yield put(setConfigLoading(false));
    } else {
      yield put(setConfigLoading(false));
      toast.error(appConfigResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    yield put(setConfigLoading(false));
    console.log("error", err);
  }
}

export function* putDefaultBooking(params) {
  try {
    const getUpdateBookingSettingUrl = `${process.env.REACT_APP_BASEURL}${SAVE_AUTO_BOOKING_URL}`;
    const { postData } = params;
    const autoBookingResponse = yield call(httpCall, {
      url: getUpdateBookingSettingUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
    if (autoBookingResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: postData?.customerId,
      });
    } else {
      toast.error(autoBookingResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}

export function* watchCustomerSettingsSaga() {
  yield takeLatest("PUT_DEFAULT_ADDRESS", putDefaultAddress);
  yield takeLatest("PUT_DEFAULT_COURIER", putCourierSettings);
  yield takeLatest("PUT_AUTO_BOOKING",putDefaultBooking);
  yield takeLatest("PUT_DEFAULT_BANK", putDefaultBank);
  yield takeLatest("PUT_PACKAGE_VOLUME", putPackageVolume);
  yield takeLatest("PUT_MANIFEST_SETTING", putManifestSetting);
  yield takeLatest("PUT_FULFILMENT_SETTING", putFulfillmentSetting);
  yield takeLatest("GET_CUSTOMER_SETTING", getCustomerSetting);
  yield takeLatest('GET_APP_CONFIG',getAppConfig);
}

export default function* CustomerSettingsSaga() {
  yield all([watchCustomerSettingsSaga()]);
}
