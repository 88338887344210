import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { httpCall, GET_EXCEL_DATA_URL } from "../../utils/api";

import { toast } from "react-toastify";
import {
  setExcelData,
  setIsExcelDataLoading,
} from "../slices/ExcelDownloadSlice";
import { getIsRouteB2bOrB2c } from "../slices/ComponentFunctionSlice";

export function* GetExcelData(params) {
  yield put(setIsExcelDataLoading(true));
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { payload, dataType } = params;
  
  const downloadExcelUrl = `${process.env.REACT_APP_BASEURL}${GET_EXCEL_DATA_URL}/${dataType}/${pathName}`;
  try {
    const ExcelData = yield call(httpCall, {
      url: downloadExcelUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        
      },
      data: payload,
    });
    if (ExcelData?.success) {
      yield put(setExcelData(ExcelData?.data?.rows));
      yield put(setIsExcelDataLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setIsExcelDataLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsExcelDataLoading(false));
  }
}

export function* watchExcelDataSaga() {
  yield takeLatest("GET_EXCEL_DATA", GetExcelData);
}

export default function* ExcelDataSaga() {
  yield all([watchExcelDataSaga()]);
}
