import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Flex,
  Spacer,
  Center,
  Button,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import CustomerTypeInfo from "./CustomerForm/CustomerTypeInfo";
import CustomerJobForm from "./CustomerForm/CustomerJobsForm";
import ShipmentCountForm from "./CustomerForm/ShipmentCountForm";
import CustomerSellMedia from "./CustomerForm/CustomerSellMedia";
import ServiceTypeForm from "./CustomerForm/ServiceTypeForm";
import CompanyDetailsForm from "./CustomerForm/CompanyDetailsForm";
import NormalCustomerDetailsForm from "./CustomerForm/NormalCustomerDetailsForm";
import KycDocumentForm from "./CustomerForm/KycDocumentForm";
import {
  getCustomerType,
  setCustomerInfoFormData,
} from "../../redux/slices/CustomerDetailsSlice";
import { CustomerType } from "../../utils/utilityConstants";
import ProductCategoryForm from "./CustomerForm/ProductCategoryForm";
const CustomerInfoContainer = () => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerType);
  const [customerFormData, setCustomerFormData] = useState(
    JSON.parse(localStorage.getItem("customerFormData"))
  );
  const [formNumber, setFormNumber] = useState(1);
  const onChange = (field, value) => {
    const customerData = { ...customerFormData };
    customerData[field] = value;
    dispatch(setCustomerInfoFormData(customerData));
    setCustomerFormData(customerData);
    localStorage.setItem("customerFormData", JSON.stringify(customerData));
  };
  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem("customerFormData"));
    if (storedValue) {
      setCustomerFormData(storedValue);
    }
  }, []);
  useEffect(() => {
    const { avgShipment, cpMobile, cpName, email } = customerInfo || {};
    if (
      [email, cpMobile, cpName].every(
        (value) => value !== null && value !== undefined
      ) &&
      avgShipment != 0
    ) {
      setFormNumber(8);
    }
  }, [customerInfo]);
  const handleNext = () => {
    const isNormalOrOther =
      customerFormData?.customerType === CustomerType.NORMAL ||
      customerFormData?.customerType === CustomerType.Other;

    let increment;
    if (isNormalOrOther) {
      if (formNumber === 1) {
        increment = 2;
      } else if (formNumber === 3) {
        increment = 4;
      } else {
        increment = 1;
      }
    } else {
      increment = 1;
    }

    setFormNumber(formNumber + increment);
  };

  const handlePrev = () => {
    const isNormalOrOther =
      customerFormData?.customerType === CustomerType.NORMAL ||
      customerFormData?.customerType === CustomerType.Other;

    let decrement;

    if (isNormalOrOther) {
      if (formNumber === 7) {
        decrement = 4;
      } else if (formNumber === 3) {
        decrement = 2;
      } else {
        decrement = 1;
      }
    } else {
      decrement = 1;
    }

    setFormNumber(formNumber - decrement);
  };

  const isDisableNext = () => {
    if (formNumber === 1 && customerFormData?.customerType) {
      return false;
    } else if (
      formNumber === 2 &&
      customerFormData?.shipmentType?.length >= 1
    ) {
      return false;
    } else if (formNumber === 3 && customerFormData?.avgShipment) {
      return false;
    } else if (formNumber === 4 && customerFormData?.customerNeed) {
      return false;
    } else if (
      formNumber === 5 &&
      customerFormData?.productCategory?.length >= 1
    ) {
      return false;
    } else if (formNumber === 6 && customerFormData?.sellMedia?.length >= 1) {
      return false;
    } else if (
      formNumber === 7 &&
      customerFormData?.companyDetails &&
      Object.keys(customerFormData?.companyDetails).length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isPrevButton = formNumber === 1 || formNumber === 8;
  return (
    <VStack px={[5]} py={[5, 1]} spacing={[5, 0]} h={"100%"}>
      {formNumber != 8 && (
        <Heading
          p={[1, 5]}
          rounded={"md"}
          bg={"white"}
          pos={"sticky"}
          top={0}
          as="h1"
          textAlign={"center"}
          size={["md", "lg"]}
          color="blue.500"
        >
          Welcome to Quixgo. Please answer a few questions for serving you in
          the best way.
        </Heading>
      )}
      <Center bg={"white"}>
        <Card bg={"white"}>
          <CardBody>
            {formNumber === 1 && (
              <CustomerTypeInfo
                onChange={onChange}
                value={customerFormData?.customerType}
              />
            )}
            {formNumber === 2 &&
              customerFormData?.customerType !== CustomerType.NORMAL && (
                <ServiceTypeForm
                  onChange={onChange}
                  value={customerFormData?.shipmentType}
                />
              )}
            {formNumber === 3 && (
              <ShipmentCountForm
                onChange={onChange}
                value={customerFormData?.avgShipment}
              />
            )}
            {formNumber === 4 &&
              customerFormData?.customerType !== CustomerType.NORMAL && (
                <CustomerJobForm
                  onChange={onChange}
                  value={customerFormData?.customerNeed}
                />
              )}
            {formNumber === 5 &&
              customerFormData?.customerType !== CustomerType.NORMAL && (
                <ProductCategoryForm
                  onChange={onChange}
                  value={customerFormData?.productCategory}
                />
              )}
            {formNumber === 6 &&
              customerFormData?.customerType !== CustomerType.NORMAL && (
                <CustomerSellMedia
                  onChange={onChange}
                  value={customerFormData?.sellMedia}
                />
              )}
            {formNumber === 7 &&
              (customerFormData?.customerType === CustomerType.NORMAL ||
              customerFormData?.customerType === CustomerType.Other ? (
                <NormalCustomerDetailsForm
                  onChange={onChange}
                  value={
                    customerFormData?.normalCustomerDetails
                      ? customerFormData?.normalCustomerDetails
                      : {}
                  }
                  next={handleNext}
                />
              ) : (
                <CompanyDetailsForm
                  onChange={onChange}
                  value={
                    customerFormData?.companyDetails
                      ? customerFormData?.companyDetails
                      : {}
                  }
                  next={handleNext}
                />
              ))}
            {formNumber === 8 && (
              <KycDocumentForm
                onChange={onChange}
                value={customerFormData?.kyc ? customerFormData?.kyc : {}}
                data={customerFormData}
              />
            )}
          </CardBody>
          <CardFooter>
            <Flex w="100%">
              <Button
                borderRadius={"sm"}
                colorScheme={"blue"}
                visibility={isPrevButton ? "hidden" : "visible"}
                onClick={handlePrev}
              >
                Previous
              </Button>
              <Spacer />
              {formNumber !== 8 && (
                <Button
                  borderRadius={"sm"}
                  colorScheme={"blue"}
                  visibility={formNumber === 7 ? "hidden" : "visible"}
                  onClick={handleNext}
                  isDisabled={isDisableNext()}
                >
                  Next
                </Button>
              )}
            </Flex>
          </CardFooter>
        </Card>
      </Center>
    </VStack>
  );
};

export default CustomerInfoContainer;
