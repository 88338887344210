import { createSlice } from "@reduxjs/toolkit";
export const ComponentFunctionSlice = createSlice({
  name: "ComponentFunctionSlice",
  initialState: {
    ManifestPageSize: "_auto",
    filterFormData: {},
    isRouteB2bOrB2c: "",
    sortBy: "createdAt",
    order: "desc",
    checkBoxCondition: true,
    page: 1,
    limit: 10,
    isTokenInvalid: false,
  },
  reducers: {
    setCheckBoxCondition: (state, action) => {
      state.checkBoxCondition = action.payload;
    },
    setIsTokenInvalid: (state, action) => {
      state.isTokenInvalid = action.payload;
    },
    setManifestPageSize: (state, action) => {
      state.ManifestPageSize = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFilterFormData: (state, action) => {
      state.filterFormData = action.payload;
    },
    setIsRouteB2bOrB2c: (state, action) => {
      state.isRouteB2bOrB2c = action.payload;
    },
  },
});
export const {
  setCheckBoxCondition,
  setManifestPageSize,
  setFilterFormData,
  setIsRouteB2bOrB2c,
  setOrder,
  setSortBy,
  setPage,
  setLimit,
  setIsTokenInvalid,
} = ComponentFunctionSlice.actions;
export const getCheckBoxCondition = (state) =>
  state.ComponentFunctionSlice.checkBoxCondition;
export const getIsTokenInvalid = (state) =>
  state.ComponentFunctionSlice.isTokenInvalid;
export const getManifestPageSize = (state) =>
  state.ComponentFunctionSlice.ManifestPageSize;
export const getSortBy = (state) => state.ComponentFunctionSlice.sortBy;
export const getOrder = (state) => state.ComponentFunctionSlice.order;
export const getPage = (state) => state.ComponentFunctionSlice.page;
export const getLimit = (state) => state.ComponentFunctionSlice.limit;
export const getIsRouteB2bOrB2c = (state) =>
  state.ComponentFunctionSlice.isRouteB2bOrB2c;
export const getFilterFormData = (state) =>
  state.ComponentFunctionSlice.filterFormData;
export default ComponentFunctionSlice.reducer;
