import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import { getCustomerInfo } from "../../redux/slices/CustomerDetailsSlice";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { BiRupee, BiCheck } from "react-icons/bi";
import { OfferRecharge } from "./RechargeOfferConstants";
import { getPaymentAccessKey } from "../../redux/slices/PaymentDetailsSlice";
import { PGPAYMENTMODE, QUIXPAYMENTMODE } from "../../utils/utilityConstants";
import { getIsRouteB2bOrB2c } from "../../redux/slices/ComponentFunctionSlice";
const easebuzzCheckout = new window.EasebuzzCheckout("NLWOX22IPE", "prod");

const RechargeModel = ({ isOpen, onClose }) => {
  const toast = useToast();
  const isB2bOrB2c = useSelector(getIsRouteB2bOrB2c);
  const [price, setPrice] = useState();
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerInfo);
  const loggedUser = useSelector(getLoggedUser);
  const accessKey = useSelector(getPaymentAccessKey);
  const handleAccessKeyCreation = async () => {
    const transactionId = `${isB2bOrB2c}-${customerInfo.customerId}-${
      loggedUser.annotation_id
    }-${Date.now()}`;
    const productInfo = "wallet recharge";

    const accessKeyParams = {
      txnid: transactionId,
      amount: price,
      productInfo: productInfo,
      firstName: loggedUser.firstName,
      phone: loggedUser.mobile,
      email: loggedUser.email,
    };
    dispatch({ type: "GET_PAYMENT_TOKEN", payload: accessKeyParams });
    onClose();
  };
  useEffect(() => {
    if (accessKey) {
      easebuzzCheckout.initiatePayment(easyBuzzPaymentPrams);
    }
  }, [accessKey]);

  const handlePaymentResponse = (response) => {
    if (response.status === "success") {
      const paymentMode =
        response.mode === PGPAYMENTMODE.CREDIT
          ? QUIXPAYMENTMODE.CREDIT
          : response.mode === PGPAYMENTMODE.DEBIT
          ? QUIXPAYMENTMODE.DEBIT
          : response.mode === PGPAYMENTMODE.EBT
          ? QUIXPAYMENTMODE.EBT
          : response.mode === PGPAYMENTMODE.UPI
          ? QUIXPAYMENTMODE.UPI
          : QUIXPAYMENTMODE.MOBILEWALLET;
      const modeDetails =
        paymentMode === QUIXPAYMENTMODE.UPI
          ? response.upi_va
          : paymentMode === QUIXPAYMENTMODE.DEBIT
          ? response.cardnum
          : paymentMode === QUIXPAYMENTMODE.CREDIT
          ? response.cardnum
          : paymentMode === QUIXPAYMENTMODE.EBT
          ? response.bank_name
          : QUIXPAYMENTMODE.MOBILEWALLET;
      const params = {
        customerId: customerInfo.customerId,
        transactionType: "cr",
        amount: response.amount,
        transactionNumber: response.txnid,
        paymentMode: paymentMode,
        modeDetails: modeDetails,
        transactionDate: response.addedon,

        otherInfo: {
          error: response.error,
          bankRefNum: response.bank_ref_num,
          easepayid: response.easepayid,
        },
      };

      dispatch({ type: "SET_TRANSACTION_DETAILS", payload: params });
    } else {
      toast({
        title: "Payment Failed",
        description: response.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const easyBuzzPaymentPrams = {
    access_key: accessKey,
    // '60d6bfebe155b5955c9fc5f1fb69732da7587a2c025f26f6d88e8f56f32c93ae', // access key received via Initiate Payment
    onResponse: (response) => {
      console.log(response);
      handlePaymentResponse(response);
    },
    theme: "#123456", // color hex
  };
  const isOfferVisible =
    !customerInfo.gst ||
    (!customerInfo?.isDiscountApplicable && customerInfo?.gst)
      ? true
      : false;
  return (
    <Modal
      closeOnOverlayClick={false}
      size={["xs", "md"]}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={() => setPrice(0)} />
        <ModalBody>
          <SimpleGrid columns={1} spacing={1} mt="30px">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children={<Icon as={BiRupee} color="green.500" />}
              />
              <Input
                placeholder="0"
                type="number"
                value={price || ""}
                onChange={(e) => setPrice(Number(e.target.value))}
              />

              <InputRightElement
                onClick={handleAccessKeyCreation}
                children={<Icon as={BiCheck} w={8} h={8} color="green.500" />}
              />
            </InputGroup>
            <HStack spacing={3}>
              {[500, 1000].map((amount) => (
                <Button
                  color="white"
                  size={"xs"}
                  bg={"#3182ce"}
                  _hover={{ backgroundColor: "#3875AD" }}
                  key={amount}
                  onClick={(e) =>
                    setPrice((prev) =>
                      prev != undefined ? prev + amount : 0 + amount
                    )
                  }
                >
                  {amount}+
                </Button>
              ))}
            </HStack>
            <VStack>
              {isOfferVisible &&
                OfferRecharge.map((offer) => (
                  <Text key={offer.value} size={["xs", "lg"]}>
                    Recharge For{" "}
                    <Button
                      size={["xs", "sm"]}
                      bg={"#3182ce"}
                      _hover={{ backgroundColor: "#3875AD" }}
                      color="white"
                      leftIcon={<BiRupee />}
                      onClick={(e) => setPrice(Number(offer.value))}
                    >
                      {offer.value}
                    </Button>
                    {offer.label}
                  </Text>
                ))}
            </VStack>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default RechargeModel;
